import { StyledMain } from "./style";

const Main: React.FC = ({ children }) => {
  return (
    <StyledMain
      className="main-content"
      style={{ padding: "calc(1rem + 2%)", paddingTop: "5rem" }}
    >
      {children}
    </StyledMain>
  );
};

export default Main;
