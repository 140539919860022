import { FC, MouseEvent } from "react";
import { User } from "../../../../types";
import { MediaBody } from "../../Media";
import { StyledMedia, StyledName, StyledSpan, StyledNav } from "./style";

interface IProps {
  id: User["id"];
  user: User;
  activeId?: number;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const ContactItem: FC<IProps> = ({ id, user, activeId, onClick }) => {
  return (
    <StyledMedia
      id={id}
      $active={activeId === +id}
      onClick={onClick}
      style={{ alignItems: "center" }}
    >
      {/* <Brand color={item.color} style={{ width: "2.3rem", height: "2.3rem" }} /> */}
      <MediaBody ml="10px">
        <StyledName>{user.username}</StyledName>
        <StyledSpan>{user.role?.name}</StyledSpan>
      </MediaBody>
      <StyledNav>
        {/* <StyledNavBtn>{"Star"}</StyledNavBtn>
        <StyledNavBtn>{"Edit"}</StyledNavBtn> */}
      </StyledNav>
    </StyledMedia>
  );
};

export default ContactItem;
