import React from "react";
import { User } from "../../types";
import Header from "./Header";

interface IProps {
  user: User | null;
  // companies: Company[];
  // activeCompany: Company | null;
  hasSidebar?: boolean;
  sidebarLayout?: 1 | 2;
  menu: any;
  // activeScreen: Screens | null;
  // unreadedNotifications: Notification[];
}

const Layout: React.FC<IProps> = ({
  user,
  // companies,
  // activeCompany,
  children,
  hasSidebar,
  sidebarLayout,
  menu,
  // activeScreen,
  // unreadedNotifications,
}) => {
  return (
    <>
      <Header
        user={user}
        hasSidebar={hasSidebar}
        sidebarLayout={sidebarLayout}
        // companies={companies}
        // activeCompany={activeCompany}
        menu={menu}
        // activeScreen={activeScreen}
        // unreadedNotifications={unreadedNotifications}
      />
      {children}
    </>
  );
};

export default Layout;
