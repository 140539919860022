import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineTrash } from "react-icons/hi";
import { LOCALES } from "../../../config/locale";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { Allergen, Dish, DishCategory, EditedDish, Plan } from "../../../types";
import { Button } from "../../Common/Button";
import { Card } from "../../Common/Card";
import { FileUploadModal } from "../../Common/FileUploadModal";
import { Input, Label } from "../../Common/Forms";
import { Checkbox } from "../../Common/Forms/Checkbox";
import { CustomSelect } from "../../Common/Forms/CustomSelect";
import { Col, Row } from "../../Common/Grid";
import ScrollBar from "../../Common/Scrollbar";
import { Table } from "../../Common/Table";
import { StyledHeader, StyledBody, FormControl, StyledTheadTR } from "./style";
import DatePicker from "react-multi-date-picker";

type Props = {
  dish: Dish | null;
  dishCategories: DishCategory[];
  plans: Plan[];
  allergens: Allergen[];
  dishes: Dish[];
  dishTypologies: string[];
  onSubmit: (dish: EditedDish) => any;
  onCancel?: (dishId: Dish["id"]) => any;
};

const MONTHS = [
  ["Gen", "g"],
  ["Feb", "f"],
  ["Mar", "m"],
  ["Apr", "a"],
  ["Mag", "m"],
  ["Giu", "g"],
  ["Lug", "l"],
  ["Ago", "a"],
  ["Set", "s"],
  ["Ott", "o"],
  ["Nov", "n"],
  ["Dic", "d"],
];
const WEEK_DAYS = [
  ["Dom", "D"],
  ["Lun", "L"],
  ["Mar", "M"],
  ["Mer", "M"],
  ["Gio", "G"],
  ["Ven", "V"],
  ["Sab", "S"],
];
const PLATE_PARTITION = ["hotPlate", "coldPlate", "magazine"];

const DishForm: React.FC<Props> = ({
  dish,
  dishCategories,
  plans,
  allergens,
  dishes,
  dishTypologies,
  onSubmit,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const {
    isOpen: isModalUploadOpen,
    open: openModalUpload,
    close: closeModalUpload,
  } = useDisclosure();

  const formik = useFormik({
    initialValues: {
      id: dish?.id || undefined,
      name:
        dish?.name ||
        LOCALES.reduce((list: any, item) => {
          list[item] = "";
          return list;
        }, {}),
      subname:
        dish?.subname ||
        LOCALES.reduce((list: any, item) => {
          list[item] = "";
          return list;
        }, {}),
      description:
        dish?.description ||
        LOCALES.reduce((list: any, item) => {
          list[item] = "";
          return list;
        }, {}),
      visible: !!dish?.visible,
      imgUrls: dish?.imgUrls || [],
      nutritionalTable: dish?.nutritionalTable || [],
      categoryId: dish && dish.plateCategory ? dish.plateCategory.id : null,
      planIds: dish && dish.plans ? dish.plans.map((item) => item.id) : [],
      plateIngredients: dish?.plateIngredients || [],
      allergenIds:
        dish && dish.allergens ? dish.allergens.map((item) => item.id) : [],
      recommendedPlates: dish?.recommendedPlates || [],
      daysOfWeek: dish?.daysOfWeek || [],
      availabilityDates: dish?.availabilityDates || [],
      typology: dish?.typology || null,
      partitionType: dish?.partitionType || null,
    },
    enableReinitialize: true,
    onSubmit,
  });

  const dishTypologiesOptions = useMemo(() => {
    return [
      { value: null, label: t("components.dishForm.typologyPlaceholder") },
      ...dishTypologies.map((typology) => ({
        value: typology,
        label:
          typology.charAt(0).toUpperCase() +
          typology.slice(1).replaceAll("_", " ").toLocaleLowerCase(),
      })),
    ];
  }, [dishTypologies, t]);

  const selectedDishTypologyOptions = useMemo(() => {
    if (!formik.values.typology) {
      return null;
    }

    const typology = dishTypologies.find(
      (item) => item === formik.values.typology
    );

    return {
      value: typology,
      label:
        typology!.charAt(0).toUpperCase() +
        typology!.slice(1).replaceAll("_", " ").toLocaleLowerCase(),
    };
  }, [formik.values.typology, dishTypologies]);

  const categoriesOptions = useMemo(() => {
    return dishCategories.map((category) => ({
      value: category.id,
      label: category.name[i18n.language],
    }));
  }, [dishCategories, i18n.language]);

  const selectedCategoryOptions = useMemo(() => {
    if (!formik.values.categoryId) {
      return null;
    }

    const category = dishCategories.find(
      (item) => item.id === formik.values.categoryId
    );

    return {
      value: category?.id,
      label: category?.name[i18n.language],
    };
  }, [formik.values.categoryId, dishCategories, i18n.language]);

  const plansOptions = useMemo(() => {
    return plans.map((plan) => ({
      value: plan.id,
      label: plan.name,
    }));
  }, [plans]);

  const selectedPlanOptions = useMemo(() => {
    return formik.values.planIds
      ? formik.values.planIds.map((planId) => {
          const plan = plans.find((p) => p.id === planId);

          return {
            value: plan?.id,
            label: plan?.name,
          };
        })
      : [];
  }, [formik.values.planIds, plans]);

  const allergensOptions = useMemo(() => {
    return allergens.map((allergen) => ({
      value: allergen.id,
      label: allergen.name[i18n.language],
    }));
  }, [i18n.language, allergens]);

  const selectedAllergensOptions = useMemo(() => {
    return formik.values.allergenIds
      ? formik.values.allergenIds.map((allergenId) => {
          const allergen = allergens.find((i) => i.id === allergenId);

          return {
            value: allergen?.id,
            label: allergen?.name[i18n.language],
          };
        })
      : [];
  }, [formik.values.allergenIds, i18n.language, allergens]);

  const recommendedPlatesOptions = useMemo(() => {
    return dishes.map((dish) => ({
      value: dish.id,
      label: dish.name[i18n.language],
    }));
  }, [i18n.language, dishes]);

  const selectedRecommendedPlatesOptions = useMemo(() => {
    return formik.values.recommendedPlates
      ? formik.values.recommendedPlates.map((dishId) => {
          const dish = dishes.find((i) => i.id === dishId);

          return {
            value: dish?.id,
            label: dish?.name[i18n.language],
          };
        })
      : [];
  }, [formik.values.recommendedPlates, i18n.language, dishes]);

  const daysOfWeekOptions = useMemo(() => {
    return [0, 1, 2, 3, 4, 5, 6].map((dayOfWeek) => ({
      value: dayOfWeek,
      label: t(`components.calendar.daysOfWeek.${dayOfWeek}`),
    }));
  }, [t]);

  const selectedDaysOfWeek = useMemo(() => {
    return formik.values.daysOfWeek
      ? formik.values.daysOfWeek.map((dayOfWeek) => {
          return {
            value: dayOfWeek,
            label: t(`components.calendar.daysOfWeek.${dayOfWeek}`),
          };
        })
      : [];
  }, [formik.values.daysOfWeek, t]);

  const platePartition = useMemo(() => {
    return PLATE_PARTITION.map((partition) => ({
      value: partition,
      label: t(`components.dishForm.partitionType.${partition}`),
    }));
  }, [t]);

  
  const selectedPlatePartition = useMemo(() => {
    if (!formik.values.partitionType) {
      return null;
    }

    const partitionType = PLATE_PARTITION.find(
      (item) => item === formik.values.partitionType
    );

    return {
      value: partitionType,
      label: t(`components.dishForm.partitionType.${partitionType}`),
    };
  }, [formik.values.partitionType, t]);

  const handleUploadImage = useCallback(
    async (imageUrls: string[]) => {
      formik.setFieldValue("imgUrls", [imageUrls[0]]);
      closeModalUpload();
    },
    [formik, closeModalUpload]
  );

  const handleAddRowOnNutritionalTable = useCallback(() => {
    const value = [...formik.values.nutritionalTable];

    value.push({
      label: "",
      value100g: "",
      valuePerServing: "",
    });

    formik.setFieldValue("nutritionalTable", value);
  }, [formik.values.nutritionalTable]);

  const handleDeleteNutritionalTableRow = useCallback(
    (index: number) => {
      const value = [...formik.values.nutritionalTable];

      value.splice(index, 1);

      formik.setFieldValue("nutritionalTable", value);
    },
    [formik.values.nutritionalTable]
  );

  const handleAddIngredient = useCallback(() => {
    const value = [...formik.values.plateIngredients];

    value.push(
      LOCALES.reduce((list: any, item) => {
        list[item] = "";
        return list;
      }, {})
    );

    formik.setFieldValue("plateIngredients", value);
  }, [formik.values.plateIngredients]);

  const handleDeleteIngredient = useCallback(
    (index: number) => {
      const value = [...formik.values.plateIngredients];

      value.splice(index, 1);

      formik.setFieldValue("plateIngredients", value);
    },
    [formik.values.plateIngredients]
  );

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
      <StyledHeader>
        {onCancel && dish && (
          <Button
            type="button"
            variant="texted"
            onClick={() => onCancel(dish.id)}
            marginRight="1.5rem"
          >
            {t("common.delete")}
          </Button>
        )}

        <Button type="submit" variant="outlined">
          {t("common.save")}
        </Button>
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <FormControl>
            <Label htmlFor="name">{t("components.dishForm.name")}</Label>
            {LOCALES.map((locale) => (
              <Row alignItems="center" key={locale}>
                <Col sm>{t(`common.locale.${locale}`)}:</Col>
                <Col sm={10}>
                  <Input
                    placeholder={t("components.dishForm.name")}
                    px="0px"
                    id={`name.${locale}`}
                    {...formik.getFieldProps(`name.${locale}`)}
                  />
                </Col>
              </Row>
            ))}
          </FormControl>

          <FormControl>
            <Label htmlFor="subname">{t("components.dishForm.subname")}</Label>
            {LOCALES.map((locale) => (
              <Row alignItems="center" key={locale}>
                <Col sm>{t(`common.locale.${locale}`)}:</Col>
                <Col sm={10}>
                  <Input
                    placeholder={t("components.dishForm.subname")}
                    px="0px"
                    id={`subname.${locale}`}
                    {...formik.getFieldProps(`subname.${locale}`)}
                  />
                </Col>
              </Row>
            ))}
          </FormControl>

          <FormControl>
            <Label htmlFor="description">
              {t("components.dishForm.desciption")}
            </Label>
            {LOCALES.map((locale) => (
              <Row alignItems="center" key={locale}>
                <Col sm>{t(`common.locale.${locale}`)}:</Col>
                <Col sm={10}>
                  <textarea
                    id={`description.${locale}`}
                    {...formik.getFieldProps(`description.${locale}`)}
                    rows={3}
                  />
                </Col>
              </Row>
            ))}
          </FormControl>

          <FormControl>
            <Label htmlFor="typology">
              {t("components.dishForm.typology")}
            </Label>
            <Row alignItems="center">
              <Col>
                <CustomSelect
                  options={dishTypologiesOptions}
                  value={selectedDishTypologyOptions}
                  placeholder={t("components.dishForm.typologyPlaceholder")}
                  onChange={(options: unknown) => {
                    formik.setFieldValue(
                      "typology",
                      (options as { value: number; label: string }).value
                    );
                  }}
                />
              </Col>
            </Row>
          </FormControl>

          <FormControl>
            <Label htmlFor="visible">{t("components.dishForm.visible")}</Label>
            <Row alignItems="center">
              <Col sm={10}>
                <Checkbox
                  id="visible"
                  checked={formik.values.visible}
                  label={
                    formik.values.visible
                      ? t("components.dishForm.visibleLabel")
                      : t("components.dishForm.notVisibleLabel")
                  }
                  {...formik.getFieldProps("visible")}
                />
              </Col>
            </Row>
          </FormControl>

          <FormControl>
            <Label htmlFor="imageUrls">
              {t("components.dishForm.imageUrls")}
            </Label>
            <Row alignItems="center">
              <Col>
                {formik.values.imgUrls.length > 0 ? (
                  <img src={formik.values.imgUrls[0]} alt="dish" />
                ) : (
                  <p>{t("components.dishForm.imageUrlPlaceholder")}</p>
                )}
              </Col>
            </Row>
            <Row alignItems="center" marginTop="0.5rem">
              <Col>
                <Button color="light" onClick={openModalUpload}>
                  {t("common.upload")}
                </Button>
              </Col>
            </Row>
          </FormControl>

          <FormControl>
            <Label htmlFor="plateIngredients">
              {t("components.dishForm.plateIngredients")}
            </Label>
            <Row alignItems="center">
              <Col>
                {formik.values.plateIngredients.map((row, index) => (
                  <Card key={index} p="0.5rem" marginBottom="0.5rem">
                    {LOCALES.map((locale) => (
                      <Row alignItems="center" key={`${index}-${locale}`}>
                        <Col sm>{t(`common.locale.${locale}`)}:</Col>
                        <Col sm={10}>
                          <Input
                            placeholder={t(
                              "components.dishForm.plateIngredients"
                            )}
                            px="0px"
                            id={`plateIngredients[${index}][${locale}]`}
                            {...formik.getFieldProps(
                              `plateIngredients[${index}][${locale}]`
                            )}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      size="md"
                      variant="texted"
                      iconButton
                      onClick={() => handleDeleteIngredient(index)}
                    >
                      <HiOutlineTrash />
                    </Button>
                  </Card>
                ))}
              </Col>
            </Row>
            <Row alignItems="center">
              <Col>
                <Button color="light" onClick={handleAddIngredient}>
                  {t("common.add")}
                </Button>
              </Col>
            </Row>
          </FormControl>

          <FormControl>
            <Label htmlFor="nutritionalTable">
              {t("components.dishForm.nutritionalTable")}
            </Label>
            <Row alignItems="center">
              <Col>
                <Card>
                  <Table bordered>
                    <thead>
                      <StyledTheadTR>
                        <th>
                          {t("components.dishForm.nutritionalTableLabel")}
                        </th>
                        <th>
                          {t("components.dishForm.nutritionalTableValue100g")}
                        </th>
                        <th>
                          {t(
                            "components.dishForm.nutritionalTableValuePerServing"
                          )}
                        </th>
                        <th />
                      </StyledTheadTR>
                    </thead>
                    <tbody>
                      {formik.values.nutritionalTable.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <Input
                              placeholder={t(
                                "components.dishForm.nutritionalTableLabel"
                              )}
                              px="0px"
                              id={`nutritionalTable[${index}].label`}
                              {...formik.getFieldProps(
                                `nutritionalTable[${index}].label`
                              )}
                            />
                          </td>
                          <td>
                            <Input
                              placeholder={t(
                                "components.dishForm.nutritionalTableValue100g"
                              )}
                              px="0px"
                              id={`nutritionalTable[${index}].value100g`}
                              {...formik.getFieldProps(
                                `nutritionalTable[${index}].value100g`
                              )}
                            />
                          </td>
                          <td>
                            <Input
                              placeholder={t(
                                "components.dishForm.nutritionalTableValuePerServing"
                              )}
                              px="0px"
                              id={`nutritionalTable[${index}].valuePerServing`}
                              {...formik.getFieldProps(
                                `nutritionalTable[${index}].valuePerServing`
                              )}
                            />
                          </td>
                          <td>
                            <Button
                              size="md"
                              variant="texted"
                              iconButton
                              onClick={() =>
                                handleDeleteNutritionalTableRow(index)
                              }
                            >
                              <HiOutlineTrash />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
            <Row alignItems="center">
              <Col>
                <Button color="light" onClick={handleAddRowOnNutritionalTable}>
                  {t("common.add")}
                </Button>
              </Col>
            </Row>
          </FormControl>

          <FormControl>
            <Label htmlFor="daysOfWeek">
              {t("components.dishForm.daysOfWeek")}
            </Label>
            <Row alignItems="center">
              <Col>
                <CustomSelect
                  options={daysOfWeekOptions}
                  value={selectedDaysOfWeek}
                  placeholder={t("components.dishForm.daysOfWeek")}
                  isMulti
                  onChange={(options: unknown) => {
                    formik.setFieldValue(
                      "daysOfWeek",
                      (options as { value: number; label: string }[]).map(
                        (option) => option.value
                      )
                    );
                  }}
                />
              </Col>
            </Row>
          </FormControl>

          <FormControl>
            <Label htmlFor="partitionType">
              {t("components.dishForm.partition")}
            </Label>
            <Row alignItems="center">
              <Col>
                <CustomSelect
                  options={platePartition}
                  value={selectedPlatePartition}
                  placeholder={t("components.dishForm.partition")}
                  onChange={(options: unknown) => {
                    formik.setFieldValue(
                      "partitionType",
                      (options as { value: number; label: string }).value
                    );
                  }}
                />
              </Col>
            </Row>
          </FormControl>

          <FormControl>
            <Label htmlFor="availabilityDates">
              {t("components.dishForm.availabilityDates")}
            </Label>
            <Row alignItems="center">
              <Col>
                <DatePicker
                  multiple
                  value={formik.values.availabilityDates}
                  onChange={(dates) =>
                    formik.setFieldValue("availabilityDates", dates)
                  }
                  minDate={new Date()}
                  // render={<Input id="" name="" />}
                  format="DD/MM/YYYY"
                  containerStyle={{ width: "100%" }}
                  style={{
                    width: "100%",
                    height: "38px",
                    boxSizing: "border-box",
                    padding: "0.46875rem 0.625rem",
                    fontSize: "0.875rem",
                    borderRadius: "4px",
                    border: "1px solid #c0ccda",
                  }}
                  months={MONTHS}
                  weekDays={WEEK_DAYS}
                />
              </Col>
            </Row>
          </FormControl>

          {formik.values.id && (
            <FormControl>
              <Label htmlFor="imageUrls">
                {t("components.dishForm.category")}
              </Label>
              <Row alignItems="center">
                <Col>
                  <CustomSelect
                    options={categoriesOptions}
                    value={selectedCategoryOptions}
                    placeholder={t("components.dishForm.categoryPlaceholder")}
                    onChange={(options: unknown) => {
                      formik.setFieldValue(
                        "categoryId",
                        (options as { value: number; label: string }).value
                      );
                    }}
                  />
                </Col>
              </Row>
            </FormControl>
          )}

          {formik.values.id && (
            <FormControl>
              <Label htmlFor="plans">{t("components.dishForm.plans")}</Label>
              <Row alignItems="center">
                <Col>
                  <CustomSelect
                    options={plansOptions}
                    value={selectedPlanOptions}
                    placeholder={t("components.dishForm.plansPlaceholder")}
                    isMulti
                    onChange={(options: unknown) => {
                      formik.setFieldValue(
                        "planIds",
                        (options as { value: number; label: string }[]).map(
                          (option) => option.value
                        )
                      );
                    }}
                  />
                </Col>
              </Row>
            </FormControl>
          )}

          {formik.values.id && (
            <FormControl>
              <Label htmlFor="allergens">
                {t("components.dishForm.allergens")}
              </Label>
              <Row alignItems="center">
                <Col>
                  <CustomSelect
                    options={allergensOptions}
                    value={selectedAllergensOptions}
                    placeholder={t("components.dishForm.allergensPlaceholder")}
                    isMulti
                    onChange={(options: unknown) => {
                      formik.setFieldValue(
                        "allergenIds",
                        (options as { value: number; label: string }[]).map(
                          (option) => option.value
                        )
                      );
                    }}
                  />
                </Col>
              </Row>
            </FormControl>
          )}

          {formik.values.id && (
            <FormControl>
              <Label htmlFor="recommendedPlates">
                {t("components.dishForm.recommendedPlates")}
              </Label>
              <Row alignItems="center">
                <Col>
                  <CustomSelect
                    options={recommendedPlatesOptions}
                    value={selectedRecommendedPlatesOptions}
                    placeholder={t("components.dishForm.recommendedPlates")}
                    isMulti
                    onChange={(options: unknown) => {
                      formik.setFieldValue(
                        "recommendedPlates",
                        (options as { value: number; label: string }[]).map(
                          (option) => option.value
                        )
                      );
                    }}
                  />
                </Col>
              </Row>
            </FormControl>
          )}
        </StyledBody>
      </ScrollBar>

      <FileUploadModal
        isOpen={isModalUploadOpen}
        onClose={closeModalUpload}
        onFileUploaded={handleUploadImage}
        maxFiles={1}
        accept="image/jpeg, image/png"
      />
    </form>
  );
};

export default DishForm;
