import { FC } from "react";
import { PNMessage } from "../../../types";
import Heading from "../../Common/Heading";
import { StyledWrap, StyledContent } from "./style";

type Props = {
  message: PNMessage;
};

const MailBody: FC<Props> = ({ message }) => {
  return (
    <StyledWrap>
      <Heading as="h5" mb="30px">
        {message.title}
      </Heading>
      <StyledContent>{message.description}</StyledContent>
    </StyledWrap>
  );
};

export default MailBody;
