import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DishForm from "../../components/Dishes/DishForm";
import Main from "../../components/Dishes/Main";
import NewsForm from "../../components/News/NewsForm";
import { useStores } from "../../hooks/useStores";
import { Dish, EditedDish, EditedNews, News } from "../../types";

export const EditNewsScreen: React.FC = observer(() => {
  const { news: newsStore } = useStores();
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const news = useMemo(() => {
    const id = Number(params.newsId);

    return newsStore.news.find((item) => item.id === id) || null;
  }, [params, newsStore.news]);

  const handleSubmit = useCallback(
    async (data: EditedNews) => {
      try {
        if (data.id) {
          await newsStore.updateNews(data.id, data);

          toast.success(t("messages.successes.newsForm.updateCompleted"));
        }
      } catch (err) {
        console.log(err);
        toast.error(t("messages.errors.newsForm.generic"));
      }
    },
    [newsStore, t]
  );

  const handleDelete = useCallback(
    async (newsId: News["id"]) => {
      if (window.confirm(t("components.newsForm.confirmDelete"))) {
        try {
          await newsStore.deleteNews(newsId);
          toast.success(t("messages.successes.newsForm.deleteCompleted"));
          navigate(`..`);
        } catch (err) {
          console.log(err);
          toast.error(t("messages.errors.newsForm.generic"));
        }
      }
    },
    [newsStore, navigate, t]
  );

  return (
    <Main>
      <NewsForm news={news} onSubmit={handleSubmit} onCancel={handleDelete} />
    </Main>
  );
});
