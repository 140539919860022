import React from "react";
import { User, LogOut } from "react-feather";
import { useTranslation } from "react-i18next";
import { classicTheme } from "../../../../styled";
import { User as TUser } from "../../../../types";
import { getUserInitials } from "../../../../utils/user";
import { AvatarInitial, Avatar } from "../../../Common/Avatar";
import { Dropdown, DropdownToggle } from "../../../Common/Dropdown";
import {
  StyledDropMenu,
  StyledAuthorName,
  StyledDropItem,
  StyledDivider,
  StyledAvatar,
} from "./style";

type Props = {
  user: TUser;
};

const ProfileDropdown: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  return (
    <Dropdown direction="down" className="dropdown-profile">
      <DropdownToggle variant="texted">
        <StyledAvatar size="sm" shape="circle">
          <AvatarInitial backgroundColor={classicTheme.colors.primary}>
            {getUserInitials(user)}
          </AvatarInitial>
        </StyledAvatar>
      </DropdownToggle>
      <StyledDropMenu>
        <Avatar size="lg" shape="circle">
          <AvatarInitial backgroundColor={classicTheme.colors.primary}>
            {getUserInitials(user)}
          </AvatarInitial>
        </Avatar>
        <StyledAuthorName>{user.username}</StyledAuthorName>
        {/* <StyledDropItem path={`/user-profile`}>
          <User size="24" />
          {t("components.profileDropdown.profile")}
        </StyledDropItem> */}
        <StyledDivider />
        <StyledDropItem path="/logout" mt="10px">
          <LogOut size="24" />
          {t("components.menu.signOut")}
        </StyledDropItem>
      </StyledDropMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
