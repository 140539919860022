import { FC } from "react";
import { useTranslation } from "react-i18next";
import { News } from "../../../types";
import Heading from "../../Common/Heading";
import { StyledWrap, StyledContent } from "./style";

type Props = {
  news: News;
};

const MailBody: FC<Props> = ({ news }) => {
  const { i18n } = useTranslation();
  return (
    <StyledWrap>
      <Heading as="h5" mb="30px">
        {news.title[i18n.language]}
      </Heading>
      <StyledContent>{news.content[i18n.language]}</StyledContent>
    </StyledWrap>
  );
};

export default MailBody;
