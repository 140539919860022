import { FC } from "react";
import Logo from "../../../assets/images/formula-pranzo-logo.svg";
import {
  StyledMedia,
  StyledMediaBody,
  StyledImage,
  StyledSignin,
} from "./style";
import SigninForm from "../SigninForm";
import { AlertMessage } from "../../../types";

type Props = {
  isSubmitting: boolean;
  message?: AlertMessage;
  onSubmit: (username: string, password: string) => void;
};

const AuthContainer: FC<Props> = ({ message, isSubmitting, onSubmit }) => {
  return (
    <StyledMedia>
      <StyledMediaBody
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledImage>
          <img src={Logo} alt="Service Key" />
        </StyledImage>
      </StyledMediaBody>
      <StyledSignin>
        <SigninForm
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          message={message}
        />
      </StyledSignin>
    </StyledMedia>
  );
};

export default AuthContainer;
