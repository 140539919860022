import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  ModalTitle,
} from "../Modal";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import env from "../../../config/env";
import { Col, Row } from "../Grid";
import { Button } from "../Button";
import { uploadToMSBucket } from "@themostaza/ms-bucket-file-uploader";

const baseStyle = {
  flex: 1,
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

type Props = {
  isOpen: boolean;
  accept?: string; //'image/jpeg, image/png'
  maxFiles?: number;
  onClose: () => void;
  onFileUploaded: (fileUrls: string[]) => void;
};

export const FileUploadModal: React.FC<Props> = ({
  isOpen,
  onClose,
  accept,
  maxFiles,
  onFileUploaded,
}) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept, maxFiles });

  const dNDStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleUpload = useCallback(async () => {
    setIsUploading(true);
    const fileUploaded: any[] = [];
    try {
      for (let file of acceptedFiles) {
        const fileUrl = await uploadToMSBucket(
          env.MOSTAZA_SERVICES_AUTH_TOKEN,
          file,
          true
        );
        fileUploaded.push(fileUrl);
      }
      onFileUploaded(fileUploaded);
    } catch (err) {
      console.log(err);
      alert(t("common.error"));
    } finally {
      setIsUploading(false);
    }
  }, [acceptedFiles, onFileUploaded, t]);

  const fileList = acceptedFiles.map((file) => (
    <li key={file.name}>{file.name}</li>
  ));

  return (
    <Modal show={isOpen} onClose={onClose}>
      <ModalHeader>
        <ModalTitle>{t("common.upload")}</ModalTitle>
        <ModalClose onClose={onClose}>x</ModalClose>
      </ModalHeader>
      <ModalBody>
        <div {...getRootProps({ style: dNDStyle })}>
          <input {...getInputProps()} />
          {t("common.dndMessage")}
        </div>
        <aside>
          <ul style={{ listStyle: "none" }}>{fileList}</ul>
        </aside>
        <Row>
          <Col textAlign="end" marginTop="1rem">
            <Button
              type={"button"}
              onClick={onClose}
              marginRight="10px"
              color="light"
            >
              {t("common.cancel")}
            </Button>
            <Button
              type={"button"}
              onClick={handleUpload}
              disabled={acceptedFiles.length === 0 || isUploading}
            >
              {t("common.upload")}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
