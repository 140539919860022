const it = {
  common: {
    confirm: "Conferma",
    password: "Password",
    backToLogin: "Torna al login",
    dndMessage:
      "Trascina qui i tuoi file da caricare, oppure clicca qui per selezionarli",
    upload: "Carica",
    locale: {
      it: "Italiano",
    },
    add: "Aggiungi",
    save: "Salva",
    saveFilter: "Salva filtro",
    updateFilter: "Aggiorna filtro",
    delete: "Cancella",
    cancel: "Annulla",
    workshifts: {
      WS_VAR: "Turno variabile",
      WS_1200: "12:00",
      WS_1230: "12:30",
      WS_1300: "13:00",
    },
  },
  components: {
    menu: {
      home: "Home",
      dishes: "Piatti",
      users: "Utenti",
      satisfaction: "Livello Soddisfazione",
      signOut: "Logout",
      orders: "Prenotazioni",
      news: "Notizie",
      pushNotifications: "Notifiche Push",
    },
    signinForm: {
      title: "Login",
      description: "Bentornato! Accedi per continuare.",
      forgotPassword: "Password dimenticata?",
      signin: "Accedi",
      email: "Email",
    },
    credentialsForm: {
      email: "Email",
      password: "Nuova password",
      confirmPassword: "Conferma nuova password",
    },
    forgotPasswordForm: {
      title: "Recupera la tua password",
      description:
        "Inserisci la tua email e invieremo un link per modificare la tua password.",
      email: "Email",
      resetPassword: "Recupera password",
      confirmMessage:
        "Richiesta inviata correttamente. Controlla la tua mail e segui le istruzioni indicate.",
    },
    changePasswordForm: {
      title: "Cambia la tua password",
      description:
        "Inserisci la tua nuova password per completare l'operazione.",
      confirmPassword: "Conferma password",
    },
    userForm: {
      title: "Crea un nuovo utente",
      email: "Indirizzo email",
      username: "Nome utente",
      confirmPassword: "Conferma password",
      company: "Azienda",
      role: "Ruolo",
      confirmDelete: "Confermi di voler eliminare questo utente?",
      referenceOffice: "Ufficio di riferimento",
      department: "Dipartimento/Reparto di appartenenza",
      service: "Utente tipo service",
      serviceLabel: "Service",
      notServiceLabel: "Non service",
      workshift: "Turno di lavoro",
    },
    dishes: {
      search: "Cerca..",
    },
    dishForm: {
      name: "Nome del piatto",
      subname: "Descrizione breve del piatto",
      desciption: "Descrizione estesa",
      imageUrls: "Foto del piatto",
      imageUrlPlaceholder: "Carica una foto del piatto",
      category: "Categoria",
      categoryPlaceholder: "Seleziona una categoria",
      ingredients: "Ingredienti",
      ingredientsPlaceholder: "Seleziona uno o più ingredienti",
      allergens: "Allergeni",
      allergensPlaceholder: "Seleziona uno o più allergeni",
      plans: "Piani",
      plansPlaceholder:
        "Seleziona uno o più piani in cui è disponibile il piatto",
      visible: "Visibilità nel menù",
      visibleLabel: "Visibile",
      notVisibleLabel: "Nascosto",
      nutritionalTable: "Tabella nutrizionale",
      nutritionalTableLabel: "Etichetta",
      nutritionalTableValue100g: "Per 100g",
      nutritionalTableValuePerServing: "Per porzione",
      confirmDelete: "Confermi di voler eliminare questo piatto?",
      plateIngredients: "Ingredienti",
      recommendedPlates: "Piatti consigliati",
      daysOfWeek: "Disponibilità (giorni della settimana)",
      partition: "Suddivisione",
      partitionType: {
        hotPlate: "Pietanza calda",
        coldPlate: "Pietanza fredda",
        magazine: "Magazzino",
      },
      availabilityDates: "Date disponibilità",
      typology: "Tipologia piatto",
      typologyPlaceholder: "Seleziona una tipologia",
    },
    calendar: {
      today: "Questa settimana",
      daysOfWeek: {
        0: "Domenica",
        1: "Lunedì",
        2: "Martedì",
        3: "Mercoledì",
        4: "Giovedì",
        5: "Venerdì",
        6: "Sabato",
      },
    },
    news: {
      searchPlaceholder: "Ricerca..",
      emptyState: "Nessuna notizia",
      edit: "Modifica",
      delete: "Cancella",
    },
    newsForm: {
      title: "Titolo",
      content: "Testo",
      confirmDelete: "Confermi di voler eliminare questa notizia?",
    },
    orders: {
      reportsBtt: "Esportazione",
      export: {
        company: "Azienda",
        day: "Giorno",
        user: "Utente",
        dish: "Piatto",
        count: "Quantità",
        referenceOffice: "Ufficio",
        partition: "Suddivisione",
        workshift: "Turno di lavoro",
      },
    },
    satisfaction: {
      feedback: "Media Feedback",
      complessiveFeedbackAvg: "Complessiva",
      varietyFeedbackAvg: "Varietà",
      quantityFeedbackAvg: "Qualità",
      total: "Totale",
      byDay: "Soddisfazione per giorno",
      noData: "Nessun feedback presente",
      votesAmount: "Numero voti",
    },
    exportModal: {
      company: "Azienda",
      noCompanies: "Nesuna azienda",
      allCompanies: "Tutte le aziende",
      fromDate: "Dal giorno",
      toDate: "Al giorno",
      exportType: "Tipologia",
      aggregateMonthlyUsers:
        "L’estrazione mensile prenderà come riferimento il mese specificato nella data di inizio estrazione",
      exportMenuDates:
        "La selezione delle date di riferimento del menù settimanale può essere fatta chiudendo questo modale e selezionando la settimana d'interesse.",
      exportMenu:
        "Per abilitare l'esportazione del menù, selezionare un'azienda",
      exportTypes: {
        dishesAggregate: "Aggregato per piatti",
        dishesCompaniesAggregate: "Aggregato per azienda e piatti",
        userDetail: "Dettaglio per singolo utente",
        usersAggregate: "Aggregato per utenti",
        usersMonthlyAggregate: "Aggregato per utenti (mensile)",
        stickyLabels: "Etichette adesive",
        weeklyMenu: "Menu settimanale",
      },
      workshift: "Turno di lavoro",
      allWorkshifts: "Tutti i turni di lavoro",
      partition: "Suddivisione",
      noPartition: "Nessuna suddivisione",
      noFilters: "Nessun filtro selezionato",
      partitionType: {
        hotPlate: "Pietanza calda",
        coldPlate: "Pietanza fredda",
        magazine: "Magazzino",
        hotColdPlates: "Pietanze calde e fredde",
        hotPlateMagazine: "Pietanze calde e magazzino",
        coldPlateMagazine: "Pietanze fredde e magazzino",
      },
    },
    pushNotifications: {
      searchPlaceholder: "Ricerca..",
      emptyState: "Nessun messaggio",
      edit: "Modifica",
      delete: "Cancella",
    },
    pnMessageForm: {
      title: "Titolo",
      description: "Testo",
      send: "Invia",
      confirmSendMessage: "Confermi di voler inviare questo messaggio?",
    },
  },
  messages: {
    formValidations: {
      common: {
        emailRequired: "Inserire l'indirizzo email",
        passwordRequired: "Inserire password",
        passwordMinLength: "Lunghezza minima di 6 caratteri",
        passwordsNotMatch: "Le password non coincidono",
      },
      pnMessageForm: {
        titleRequired: "Il titolo è obbligatorio e lungo massimo 64 caratteri",
        descriptionRequired:
          "La descrizione è obbligatoria e lunga massimo 178 caratteri",
      },
    },
    errors: {
      signinForm: {
        wrongCredentials: "Credenziali non valide",
      },
      forgotPasswordForm: {
        wrongEmail: "Email errata",
      },
      credentialsForm: { emailRequired: "Inserire l'indirizzo email" },
      changePasswordForm: {
        generic: "C'è stato un problema con l'aggiornamento della password",
      },
      dishForm: {
        generic: "Si è verificato un errore",
      },
      userForm: {
        generic: "Si è verificato un errore",
      },
      newsForm: {
        generic: "Si è verificato un errore",
      },
      filterForm: {
        generic: "Si è verificato un errore",
      },
    },
    successes: {
      forgotPasswordForm: {
        sentEmail:
          "Abbiamo inviato una mail all'indirizzo indicato contenente un link per modificare la password",
      },
      changePasswordForm: {
        updatedPassword: "La password è stata aggiornata con successo!",
      },
      updateUserForm: {
        generic: "I dati sono stati aggiornati con successo!",
        deleteCompleted: "Cancellazione eseguita correttamente",
      },
      createUserForm: {
        generic: "L'utente è stato creato con successo!",
      },
      dishForm: {
        createCompleted: "Piatto aggiunto correttamente",
        updateCompleted: "Aggiornamento eseguito correttamente",
        deleteCompleted: "Cancellazione eseguita correttamente",
      },
      newsForm: {
        createCompleted: "Notizia aggiunta correttamente",
        updateCompleted: "Aggiornamento eseguito correttamente",
        deleteCompleted: "Cancellazione eseguita correttamente",
      },
      filterForm: {
        genericEdit: "Il filtro è stato modificato con successo!",
        genericCreate: "Il filtro è stato creato con successo!",
        genericDelete: "Il filtro è stato eliminato con successo!",
      },
    },
    propmpt: {
      filterNameTitle: "Inserisci il nome del filtro: ",
      deleteFilterMessage: "Sei sicuro di voler eliminare il filtro?",
    },
  },
};

export default it;
