import tinycolor from "tinycolor2";
import styled, { css, themeGet } from "../../../styled";

export const StyledWrap = styled.div`
  width: 100%;
`;
export const StyledHeader = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  border-bottom: 1px solid ${themeGet("colors.border")};
  padding: 0 20px;
  display: flex;
  align-items: center;
  z-index: 99;
  justify-content: flex-end;

  ${(props) =>
    props.theme.name === "dark" &&
    css`
      background-color: ${tinycolor(themeGet("colors.gray900")(props))
        .darken(3)
        .toString()};
    `}
`;

export const StyledBody = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  padding: 1rem;

  ${(props) =>
    props.theme.name === "dark" &&
    css`
      background-color: transparent;
      color: ${themeGet("colors.gray500")};
    `}
`;
export const StyledTitle = styled.h3`
  color: ${themeGet("colors.text")};
  margin-bottom: 5px;
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet("colors.text3")};
  margin-bottom: 40px;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${themeGet("colors.text3")};
  font-size: 10px;
  font-weight: 500;
  font-family: ${themeGet("fonts.interui")};
  letter-spacing: 0.5px;
  margin: 15px 0;
  &:after,
  &:before {
    content: "";
    display: block;
    flex: 1;
    height: 1px;
    background-color: ${themeGet("colors.light")};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
`;
