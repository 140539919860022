import styled, { css, themeGet, tinycolor } from "../../../styled";

export const StyledHeader = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  border-bottom: 1px solid ${themeGet("colors.border")};
  padding: 0 20px;
  display: flex;
  align-items: center;
  z-index: 99;
  justify-content: flex-end;

  ${(props) =>
    props.theme.name === "dark" &&
    css`
      background-color: ${tinycolor(themeGet("colors.gray900")(props))
        .darken(3)
        .toString()};
    `}
`;

export const StyledBody = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  padding: 1rem;

  ${(props) =>
    props.theme.name === "dark" &&
    css`
      background-color: transparent;
      color: ${themeGet("colors.gray500")};
    `}
`;

export const FormControl = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledTheadTR = styled.tr`
  &:first-child {
    th {
      border-top-width: 0;
      font-size: 13px;
      background-color: #f5f6fa;
      text-align: left;
      ${(props) =>
        props.theme.name === "dark" &&
        css`
          background-color: rgba(255, 255, 255, 0.05);
        `}
    }
  }
  th:first-child {
    border-left-width: 0;
    text-align: left;
  }
  th:last-child {
    border-right-width: 0;
  }
  &:last-child {
    th {
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      padding: 8px 15px;
      color: ${themeGet("colors.text3")};
    }
  }
`;
