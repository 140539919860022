import Label from "../Label";
import { Dish, DishSection } from "../../../../types";
import { useMemo } from "react";
import DishItem from "../DishItem";

type Props = {
  dishes: Dish[];
  activeId?: number;
  clickHandler: (dish: Dish) => void;
  disableSections?: boolean;
  locale: string;
};

const AllDishes: React.FC<Props> = ({
  dishes,
  activeId,
  clickHandler,
  disableSections,
  locale,
}: Props) => {
  const sections: DishSection[] = useMemo(() => {
    if (!dishes) return [];
    let toReturn: DishSection[] = [];

    //Sort the items by their primaryText
    let tempItems = [...dishes];
    tempItems.sort((a, b) => {
      let fa = (a.name[locale] || "No-name").toLowerCase(),
        fb = (b.name[locale] || "No-name").toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    //Create a section for every new initial letter
    let currentLetter = "";
    tempItems.map((item) => {
      if (
        (item.name[locale] || "No-name")[0] !== currentLetter ||
        currentLetter === ""
      ) {
        toReturn.push({
          label: (item.name[locale] || "No-name")[0],
          items: [],
        });
        currentLetter = (item.name[locale] || "No-name")[0];
      }
      return toReturn[toReturn.length - 1].items.push(item);
    });

    return toReturn;
  }, [dishes, locale]);

  return (
    <>
      {disableSections ? (
        dishes?.map((item, index) => (
          <DishItem
            id={index}
            dish={item}
            activeId={activeId}
            onClick={() => clickHandler(item)}
            key={index}
            locale={locale}
          />
        ))
      ) : (
        <>
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <Label size="lg">{section.label}</Label>
              {section.items.map((item, itemIndex) => (
                <DishItem
                  id={item.id}
                  dish={item}
                  activeId={activeId}
                  onClick={() => clickHandler(item)}
                  key={itemIndex}
                  locale={locale}
                />
              ))}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default AllDishes;
