import { User } from "../types";

export const getUserInitials = (user: User): string => {
  if (!user.username) return "";
  let nameArray = user.username.split(" ");
  let toReturn = nameArray[0][0];

  if (nameArray.length > 1) toReturn += nameArray[1][0];

  return toReturn;
};

export const filterUsersByText = (
  text: string,
  contactItems: User[]
): User[] => {
  return contactItems.filter(
    (contactItem) =>
      contactItem.username.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
      (contactItem.role?.name &&
        contactItem.role.name.toLowerCase().indexOf(text.toLowerCase()) !== -1)
  );
};
