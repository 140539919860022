import {
  Company,
  Dish,
  DishCategory,
  Plan,
  Role,
  RoleName,
  EditedDish,
  User,
  News,
  Order,
  Allergen,
  PNMessage,
  Department,
  Filter,
} from "../types";
import dayjs from "dayjs";
import { Ability } from "@casl/ability";
import ROLES_ACLS from "../config/roles";

export const toRole = (data: any): Role => {
  let roleName: RoleName = RoleName.Authenticated;
  let name = data.attributes ? data.attributes.name : data.name;
  switch (name) {
    case "Administrator":
      roleName = RoleName.Administrator;
      break;
    case "Kitchen":
      roleName = RoleName.Kitchen;
      break;
    case "OrganizationAdmin":
      roleName = RoleName.OrganizationAdmin;
      break;
    default:
      break;
  }

  return {
    id: +data.id,
    name: roleName,
    acls: new Ability(ROLES_ACLS[roleName]),
  };
};

export const toUser = (userData: any): User => {
  let data = userData.data || userData;
  return {
    id: data.id,
    email: data.attributes?.email,
    username: data.attributes?.username,
    role: data.attributes?.role
      ? toRole(data.attributes.role.data)
      : toRole({ id: "1", attributes: { name: "Authenticated" } }), //TODO: remove
    company: data.attributes?.company
      ? toCompany(data.attributes.company.data)
      : undefined,
    referenceOffice: data.attributes?.referenceOffice,
    department: data.attributes?.user_department,
    service: data.attributes?.service === true,
    workshift: data.attributes?.workshift,
  };
};

export const toUserFromLogin = (data: any): User => {
  return {
    id: data.id,
    email: data.email,
    username: data.username,
    referenceOffice: data.attributes?.referenceOffice,
    role: data.attributes?.role
      ? toRole(data.attributes.role.data)
      : toRole({ id: "1", attributes: { name: "Authenticated" } }), //TODO: remove
    company: data.attributes?.company
      ? toCompany(data.attributes.company.data)
      : undefined,
    service: data.attributes?.servie === true,
    workshift: data.attributes?.workshift,
  };
};

export const toCompany = (data: any): Company => {
  return {
    id: data.id,
    name: data.attributes?.name,
    workingDays: data.attributes?.workingDays,
  };
};

export const toAllergen = (data: any): Allergen => ({
  id: data.id,
  imgUrl: data.attributes.imgUrl,
  name: data.attributes.name,
});

export const toDishCategory = (data: any): DishCategory => ({
  id: data.id,
  name: data.attributes.name,
  weight: data.attributes.weight,
});

export const toDish = (data: any): Dish => ({
  id: data.id,
  name: data.attributes.name,
  description: data.attributes.description,
  subname: data.attributes.subname,
  nutritionalTable: data.attributes.nutritionalTable,
  imgUrls: data.attributes.imgUrls,
  visible: data.attributes.visible,
  plateIngredients: data.attributes.plateIngredients,
  allergens: data.attributes.allergens
    ? data.attributes.allergens.data.map((item: any) => toAllergen(item))
    : [],
  plans: data.attributes.plans
    ? data.attributes.plans.data.map((item: any) => toPlan(item))
    : [],
  plateCategory: data.attributes.plate_category?.data
    ? toDishCategory(data.attributes.plate_category.data)
    : null,
  recommendedPlates: data.attributes.recommendedPlates || [],
  platesNumber: data.attributes.platesNumber,
  daysOfWeek: data.attributes.daysOfWeek || [],
  availabilityDates: data.attributes.availabilityDates || [],
  typology: data.attributes.typology || null,
  partitionType: data.attributes.partitionType || null,
});

export const toPlan = (data: any): Plan => ({
  id: data.id,
  name: data.attributes.name,
});

export const toDishPayload = (data: EditedDish): any => ({
  name: data.name,
  description: data.description,
  subname: data.subname,
  visible: data.visible,
  plate_category: data.categoryId,
  plans: data.planIds,
  plateIngredients: data.plateIngredients,
  allergens: data.allergenIds,
  imgUrls: data.imgUrls,
  nutritionalTable: data.nutritionalTable,
  recommendedPlates: data.recommendedPlates,
  daysOfWeek: data.daysOfWeek,
  availabilityDates: data.availabilityDates,
  typology: data.typology,
  partitionType: data.partitionType,
});

export const toNews = (data: any): News => ({
  id: data.id,
  content: data.attributes.content,
  title: data.attributes.title,
  createdAt: new Date(data.attributes.createdAt),
});

export const toOrder = (data: any): Order => ({
  id: data.id,
  date: dayjs(data.attributes.date),
  user: toUser(data.attributes.users_permissions_user.data),
  dishes: data.attributes.plates.data.map((plate: any) => toDish(plate)),
  complessiveFeedback: data.attributes.complessiveFeedback || undefined,
  quantityFeedback: data.attributes.quantityFeedback || undefined,
  varietyFeedback: data.attributes.varietyFeedback || undefined,
  workshift: data.attributes.workshift,
});

export const toPNMessage = (data: any): PNMessage => ({
  id: data.id,
  title: data.attributes.title,
  description: data.attributes.description,
  createdAt: dayjs(data.attributes.createdAt),
  company: data.company ? toCompany(data.company - data) : undefined,
});

export const toDepartment = (data: any): Department => ({
  id: data.id,
  department: data.attributes.department,
});

export const toFilter = (data: any): Filter => {
  return {
    id: data.id,
    company_ids: data.attributes.company_ids.data.map((item: any) =>
      toCompany(item)
    ),
    fromDate: data.attributes.fromDate,
    toDate: data.attributes.toDate,
    type: data.attributes.type,
    workShift: data.attributes.workShift,
    partition: data.attributes.partition,
    filterName: data.attributes.filterName,
  };
};
