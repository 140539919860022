import React from "react";
import RSelect, { Props, components } from "react-select";

export const Option = components.Option;
export const SingleValue = components.SingleValue;

export const CustomSelect: React.FC<Props> = (props) => {
  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      width: "100%",
      padding: "0px",
      margin: "0px",
    }),
    input: (styles: any) => ({
      ...styles,
      margin: "0px",
      padding: "0px",
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  return <RSelect styles={selectStyles} {...props} />;
};
