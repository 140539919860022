import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DishForm from "../../components/Dishes/DishForm";
import Main from "../../components/Dishes/Main";
import { useStores } from "../../hooks/useStores";
import { Dish, EditedDish } from "../../types";

export const EditDishScreen: React.FC = observer(() => {
  const { dishes } = useStores();
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dish = useMemo(() => {
    const id = Number(params.dishId);

    return dishes.dishes.find((item) => item.id === id) || null;
  }, [params, dishes.dishes]);

  const handleSubmitDish = useCallback(
    async (data: EditedDish) => {
      try {
        if (data.id) {
          await dishes.updateDish(data.id, data);

          toast.success(t("messages.successes.dishForm.updateCompleted"));
        }
      } catch (err) {
        console.log(err);
        toast.error(t("messages.errors.dishForm.generic"));
      }
    },
    [dishes, t]
  );

  const handleDelete = useCallback(
    async (dishId: Dish["id"]) => {
      if (window.confirm(t("components.dishForm.confirmDelete"))) {
        try {
          await dishes.deleteDish(dishId);
          toast.success(t("messages.successes.dishForm.deleteCompleted"));
          navigate(`..`);
        } catch (err) {
          console.log(err);
          toast.error(t("messages.errors.dishForm.generic"));
        }
      }
    },
    [dishes, navigate, t]
  );

  return (
    <Main>
      <DishForm
        dish={dish}
        onSubmit={handleSubmitDish}
        dishCategories={dishes.dishCategories}
        plans={dishes.plans}
        allergens={dishes.allergens}
        onCancel={handleDelete}
        dishes={dishes.dishes}
        dishTypologies={dishes.dishTypologies}
      />
    </Main>
  );
});
