import { FC, useState, useCallback, useMemo, useContext } from "react";
import { Button } from "../../Common/Button";
import Logo from "../../../assets/images/SK_logo.png";
// import { menuData } from "@doar/shared/data";
// import MessageDropdown from "../../components/header/message-dropdown";
// import NotificationDropdown from "../../components/header/notification-dropdown";
// import ProfileDropdown from "../../components/header/profile-dropdown";
import { Menu, X } from "react-feather";
//import { toggleSidebar, toggleBody } from "../../redux/slices/ui";
import {
  StyledHeader,
  StyledNavbarWrap,
  StyledNavbarMenu,
  StyleNavbarRight,
  StyledNavbarElement,
  StyledNavbarHeader,
  StyledNavbarBody,
  StyledMenuBtn,
  StyledSKLogo,
} from "./style";
import ProfileDropdown from "./ProfileDropdown";
import { User } from "../../../types";
import { Navbar } from "../../Common/Navbar";
import { AbilityContext } from "../../Common/Can";
import { observer } from "mobx-react";

interface IProps {
  user: User | null;
  // companies: Company[];
  // activeCompany: Company | null;
  hasSidebar?: boolean;
  sidebarLayout?: 1 | 2;
  menu: any;
  // activeScreen: Screens | null;
  // unreadedNotifications: Notification[];
}

const Header: FC<IProps> = observer(
  ({
    user,
    // companies,
    // activeCompany,
    hasSidebar,
    sidebarLayout,
    menu,
    // activeScreen,
    // unreadedNotifications,
  }) => {
    const sidebar = true;
    //const { sidebar, isBody } = useAppSelector((state) => state.ui);
    const theme = {
      background: "red",
    };
    const ability: any = useContext(AbilityContext);

    const [menuOpen, setMenuOpen] = useState(false);

    const authorizedMenu = useMemo(() => {
      // @ts-ignore
      return menu.filter((item: any) => ability.can("read", item.key));
    }, [ability, menu]);

    //   const sidebarHandler = useCallback(
    //     (_, isOpen?: "open") => {
    //       dispatch(toggleSidebar({ isOpen }));
    //     },
    //     [dispatch]
    //   );

    //   const bodyHandler = useCallback(() => {
    //     dispatch(toggleBody());
    //     dispatch(toggleSidebar({ isOpen: "open" }));
    //   }, [dispatch]);

    const menuHandler = useCallback(() => {
      setMenuOpen((prev) => !prev);
    }, []);

    return (
      <>
        <StyledHeader backgroundColor={theme.background}>
          {hasSidebar && sidebarLayout === 1 && (
            <>
              <StyledMenuBtn
                variant="texted"
                onClick={menuHandler}
                $hasSidebar={hasSidebar}
                $sidebarOpen={sidebar}
                $bodyOpen={false}
                className="menu-btn"
              >
                <Menu size={20} strokeWidth="2.5px" />
              </StyledMenuBtn>
              {/* <StyledSidebarBtn
              variant="texted"
              onClick={!isBody ? sidebarHandler : bodyHandler}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="sidebar-btn"
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn> */}
            </>
          )}
          {hasSidebar && sidebarLayout === 2 && (
            <>
              <StyledMenuBtn
                variant="texted"
                onClick={menuHandler}
                $hasSidebar={hasSidebar}
                $sidebarOpen={!sidebar}
              >
                <Menu size={20} strokeWidth="2.5px" />
              </StyledMenuBtn>
              {/* <StyledSidebarBtn
              variant="texted"
              onClick={sidebarHandler}
              $sidebarOpen={!sidebar}
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn> */}
            </>
          )}
          <StyledMenuBtn
            variant="texted"
            onClick={menuHandler}
            $hasSidebar={hasSidebar}
            $sidebarOpen={!sidebar}
          >
            <Menu size={20} strokeWidth="2.5px" />
          </StyledMenuBtn>
          {/* <StyledNavbarElement ml={["8px", "15px"]}>
          {companies.length <= 3 ? (
            <CompanyDropdown
              companies={companies}
              activeCompany={activeCompany}
            />
          ) : (
            <CompanyAdvancedSwitch
              companies={companies}
              activeCompany={activeCompany}
              primaryColor={theme.background}
            />
          )}
        </StyledNavbarElement>
        {activeScreen && activeScreen !== Screens.HOME && (
          <Breadcrumbs activeScreen={activeScreen} />
        )} */}

          <StyledNavbarWrap $isOpen={menuOpen} onClick={menuHandler}>
            <StyledNavbarMenu
              $isOpen={menuOpen}
              onClick={(e) => e.stopPropagation()}
            >
              <StyledNavbarHeader>
                <Button variant="texted" onClick={menuHandler}>
                  <X color="#7987a1" width={20} strokeWidth="2.5px" />
                </Button>
              </StyledNavbarHeader>
              <StyledNavbarBody>
                <Navbar menus={authorizedMenu} onItemClick={menuHandler} />
              </StyledNavbarBody>
            </StyledNavbarMenu>
          </StyledNavbarWrap>
          <StyleNavbarRight>
            {user && (
              <StyledNavbarElement ml={["15px", "20px", "30px"]}>
                <ProfileDropdown user={user} />
              </StyledNavbarElement>
            )}
            <StyledNavbarElement ml={["8px", "15px"]}>
              <StyledSKLogo src={Logo} alt="Service Key" />
            </StyledNavbarElement>
          </StyleNavbarRight>
        </StyledHeader>
      </>
    );
  }
);

Header.defaultProps = {
  sidebarLayout: 1,
};

export default Header;
