import { makeAutoObservable } from "mobx";
import { Stores, User } from "../types";
import api from "../services/api";

export class SessionStore {
  stores: Stores;

  isInitialized = false;
  user: User | null = null;
  isSubmitting = false;
  sessionToken: string | undefined = undefined;

  get isAuthenticated() {
    return !!this.user;
  }

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  initialize = async () => {
    try {
      this.restoreSession();
      if (this.sessionToken) {
        this.user = await api.getCurrentUser();
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isInitialized = true;
    }
  };

  login = async (email: string, password: string) => {
    this.isSubmitting = true;
    try {
      api.destroySession();
      await api.login(email, password);
      this.sessionToken = api.getSessionToken();
      this.persistSession();
      this.user = await api.getCurrentUser();

      console.log(this.user);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  forgotPassword = async (email: string) => {
    return await api.forgotPassword(email);
  };

  verifyPasswordResetCode = async (
    password: string,
    passwordConfirm: string,
    passwordResetCode: string
  ) => {
    if (password === passwordConfirm)
      return await api.verifyPasswordResetCodeAndUpdatePassword(
        passwordResetCode,
        password
      );
  };

  logout = () => {
    api.destroySession();
    this.user = null;
    this.initialize();
  };

  restoreSession = async () => {
    const data = localStorage.getItem("session");
    const sessionData = data ? JSON.parse(data) : null;

    if (sessionData) {
      this.sessionToken = sessionData.token;
    }
  };

  persistSession = async () => {
    const data = {
      token: this.sessionToken,
    };

    localStorage.setItem("session", JSON.stringify(data));
  };

  resetSession = async () => {
    this.user = null;
    this.sessionToken = undefined;
    localStorage.removeItem("session");
    await api.logout();
  };
}
