import { EditedNews, News, Stores } from "../types";
import { makeAutoObservable } from "mobx";
import api from "../services/api";

export class NewsStore {
  stores: Stores;

  isFetching = false;
  isSubmitting = false;

  news: News[] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  fetchNews = async () => {
    this.isFetching = true;

    try {
      this.news = await api.fetchNews();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  addNews = async (data: EditedNews) => {
    this.isSubmitting = true;
    let newNews;
    try {
      newNews = await api.addNews(data);
      this.news.push(newNews);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return newNews;
  };

  updateNews = async (id: News["id"], data: EditedNews) => {
    this.isSubmitting = true;

    try {
      const updatedNews = await api.updateNews(id, data);

      this.news = this.news.map((n) => (n.id === id ? updatedNews : n));
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  deleteNews = async (id: News["id"]) => {
    this.isSubmitting = true;
    try {
      await api.deleteNews(id);

      this.news = this.news.filter((n) => n.id !== id);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };
}
