import { FC } from "react";
import { Search } from "react-feather";
import { StyledGroup, StyledHeader, StyledBody, StyledList } from "./style";
import ScrollBar from "../../Common/Scrollbar";
import SearchForm from "../SearchForm";
import GroupItem from "../GroupItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationsEmptyState } from "../EmptyState";
import { News } from "../../../types";
import { Button } from "../../Common/Button";
import { HiOutlinePlus } from "react-icons/hi";

type Props = {
  news: News[];
  search: string;
  onSearchChange: (value: string) => void;
  onAddClick: () => void;
};

const Group: FC<Props> = ({ news, search, onSearchChange, onAddClick }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleClick = (id: any) => {
    navigate(`./${id}`);
  };

  return (
    <StyledGroup>
      <StyledHeader>
        <Search className="search" />
        <SearchForm value={search} onChange={onSearchChange} />
        <Button size={"md"} iconButton onClick={onAddClick}>
          <HiOutlinePlus />
        </Button>
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <StyledList>
            {news.length === 0 && <NotificationsEmptyState />}
            {news.map((item) => (
              <GroupItem
                key={item.id}
                color={"red"}
                time={item.createdAt.toLocaleDateString()}
                title={item.title[i18n.language]}
                excerpt=""
                name=""
                onClick={() => handleClick(item.id)}
              />
            ))}
          </StyledList>
        </StyledBody>
      </ScrollBar>
    </StyledGroup>
  );
};

export default Group;
