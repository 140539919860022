import { observer } from "mobx-react";
import Layout from "../../components/Layout";
import { menus } from "../../config/menu";
import { useStores } from "../../hooks/useStores";
import { Outlet } from "react-router";
import { AbilityContext } from "../../components/Common/Can";

export const AppLayout: React.FC = observer(({ children }) => {
  const { session } = useStores();

  return session.user?.role ? (
    <AbilityContext.Provider value={session.user.role.acls}>
      <Layout user={session.user} sidebarLayout={1} hasSidebar menu={menus}>
        <Outlet />
      </Layout>
    </AbilityContext.Provider>
  ) : null;
});
