import dayjs from "dayjs";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import workshifts from "../../../config/workshifts";
import {
  Company,
  EditedFilter,
  ExportOrders,
  Filter,
  OrderExportTypes,
} from "../../../types";
import { Button } from "../../Common/Button";
import { Select } from "../../Common/Forms";
import { DatePicker } from "../../Common/Forms/DatePicker";
import { Col, Row } from "../../Common/Grid";
import HRLine from "../../Common/HrLine";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalBody,
  ModalFooter,
} from "../../Common/Modal";
import { CustomSelect } from "../../Common/Forms/CustomSelect";

type Props = {
  isOpen: boolean;
  companies: Company[];
  period: dayjs.Dayjs[];
  onClose: () => void;
  onSubmit: (values: ExportOrders) => void;
  onSaveFilter: (values: EditedFilter) => void;
  onDeleteFilter: (values: EditedFilter) => void;
  filters: any[];
};

export const ExportModal: React.FC<Props> = ({
  isOpen,
  companies,
  period,
  onClose,
  onSubmit,
  onDeleteFilter,
  onSaveFilter,
  filters,
}) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<Filter | undefined>();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      company_ids: [],
      fromDate: new Date(),
      toDate: new Date(),
      type: OrderExportTypes.DishesAggregate || 0,
      partition: "",
      workShift: "",
      filter: "",
    },
    onSubmit,
  });

  useEffect(() => {
    if (selectedFilter) {
      var filterSelectedFind = filters.find(
        (filter) => filter.id === selectedFilter.id
      );
      if (!filterSelectedFind) {
        setSelectedFilter(undefined);
        formik.setFieldValue("company_ids", []);
        formik.setFieldValue("fromDate", new Date());
        formik.setFieldValue("toDate", new Date());
        formik.setFieldValue("type", "");
        formik.setFieldValue("partition", "");
        formik.setFieldValue("workShift", "");
        formik.setFieldValue("filter", "");
      }
    }
  }, [filters, formik, selectedFilter]);

  const EXPORT_TYPES = [
    {
      value: OrderExportTypes.DishesAggregate,
      label: t(`components.exportModal.exportTypes.dishesAggregate`),
    },
    {
      value: OrderExportTypes.DishesCompaniesAggregate,
      label: t(`components.exportModal.exportTypes.dishesCompaniesAggregate`),
    },
    {
      value: OrderExportTypes.UserDetail,
      label: t(`components.exportModal.exportTypes.userDetail`),
    },
    {
      value: OrderExportTypes.UsersAggregate,
      label: t(`components.exportModal.exportTypes.usersAggregate`),
    },
    {
      value: OrderExportTypes.UsersMonthlyAggregate,
      label: t(`components.exportModal.exportTypes.usersMonthlyAggregate`),
    },
    {
      value: OrderExportTypes.StickyLabels,
      label: t(`components.exportModal.exportTypes.stickyLabels`),
    },
    {
      value: OrderExportTypes.WeeklyMenu,
      label: t(`components.exportModal.exportTypes.weeklyMenu`),
    },
  ];

  const PLATE_PARTITION = [
    "hotPlate",
    "coldPlate",
    "magazine",
    "hotColdPlates",
    "hotPlateMagazine",
    "coldPlateMagazine",
  ];

  useEffect(() => {
    if (
      Number(formik.values.type) === OrderExportTypes.WeeklyMenu &&
      (formik.values.fromDate !== period[0].toDate() ||
        formik.values.toDate !== period[1].toDate())
    ) {
      formik.setFieldValue("fromDate", period[0].toDate());
      formik.setFieldValue("toDate", period[1].toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type, period]);

  const handleSaveFilter = useCallback(() => {
    let filterData: EditedFilter = {
      id: selectedFilter?.id,
      company_ids: formik.values.company_ids,
      fromDate: formik.values.fromDate,
      toDate: formik.values.toDate,
      type: formik.values.type,
      workShift: formik.values.workShift,
      partition: formik.values.partition,
      filterName: selectedFilter?.filterName,
    };

    onSaveFilter(filterData);
  }, [
    formik.values.company_ids,
    formik.values.fromDate,
    formik.values.partition,
    formik.values.toDate,
    formik.values.type,
    formik.values.workShift,
    onSaveFilter,
    selectedFilter?.filterName,
    selectedFilter?.id,
  ]);

  const handleDeleteFilter = useCallback(() => {
    let filterData: EditedFilter = {
      id: selectedFilter?.id,
      company_ids: formik.values.company_ids,
      fromDate: formik.values.fromDate,
      toDate: formik.values.toDate,
      type: formik.values.type,
      workShift: formik.values.workShift,
      partition: formik.values.partition,
      filterName: selectedFilter?.filterName,
    };
    onDeleteFilter(filterData);
  }, [
    formik.values.company_ids,
    formik.values.fromDate,
    formik.values.partition,
    formik.values.toDate,
    formik.values.type,
    formik.values.workShift,
    onDeleteFilter,
    selectedFilter?.filterName,
    selectedFilter?.id,
  ]);

  const handleCloseModal = useCallback(() => {
    setSelectedFilter(undefined);
    formik.setFieldValue("company_ids", []);
    formik.setFieldValue("fromDate", new Date());
    formik.setFieldValue("toDate", new Date());
    formik.setFieldValue("type", "");
    formik.setFieldValue("partition", "");
    formik.setFieldValue("workshift", "");
    formik.setFieldValue("filter", "");
    onClose();
  }, [formik, onClose]);

  const handleChangeFilter = (event: React.ChangeEvent) => {
    var selectedFilter_ = filters.find(
      // @ts-ignore
      (filter) => String(filter.id) === event.target.value
    );

    if (selectedFilter_) {
      formik.setFieldValue("company_ids", selectedFilter_.company_ids);
      // formik.setFieldValue("fromDate", new Date(selectedFilter_.fromDate));
      // formik.setFieldValue("toDate", new Date(selectedFilter_.toDate));
      formik.setFieldValue("type", selectedFilter_.type);
      formik.setFieldValue("partition", selectedFilter_.partition);
      formik.setFieldValue("workShift", selectedFilter_.workShift);
      // @ts-ignore
      formik.setFieldValue("filter", event.target.value);
      setSelectedFilter(selectedFilter_);
    } else {
      formik.setFieldValue("company_ids", []);
      formik.setFieldValue("fromDate", new Date());
      formik.setFieldValue("toDate", new Date());
      formik.setFieldValue("type", "");
      formik.setFieldValue("partition", "");
      formik.setFieldValue("workShift", "");
      formik.setFieldValue("filter", "");
      setSelectedFilter(undefined);
    }
  };

  const companiesOptions = useMemo(() => {
    return companies.map((company) => ({
      value: company,
      label: company.name,
    }));
  }, [companies]);

  const selectedCompanies = useMemo(() => {
    return formik.values.company_ids
      ? formik.values.company_ids.map((company: Company) => {
          return {
            value: company,
            label: company.name,
          };
        })
      : [];
  }, [formik.values.company_ids]);

  return (
    <Modal show={isOpen} onClose={handleCloseModal}>
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader>
          <ModalTitle>{t("components.orders.reportsBtt")}</ModalTitle>
          <ModalClose onClose={handleCloseModal}>x</ModalClose>
        </ModalHeader>
        <ModalBody>
          <Row alignItems="center" paddingBottom={20}>
            <Col sm>Filtri</Col>
            <Col sm={9}>
              <Select
                value={formik.values.filter}
                onChange={handleChangeFilter}
              >
                <option value="">
                  {t(`components.exportModal.noFilters`)}
                </option>
                {filters.map((filter: Filter) => (
                  <option key={filter.id} value={filter.id}>
                    {filter.filterName}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
          <HRLine my="5px" />
          <Row alignItems="center">
            <Col sm>{t("components.exportModal.company")}:</Col>
            <Col sm={9}>
              <CustomSelect
                options={companiesOptions}
                value={selectedCompanies}
                placeholder={t("components.exportModal.noCompanies")}
                isMulti
                onChange={(options: unknown) => {
                  formik.setFieldValue(
                    "company_ids",
                    (options as { value: number; label: string }[]).map(
                      (option) => option.value
                    )
                  );
                }}
              />
            </Col>
          </Row>
          <HRLine my="5px" />
          <Row alignItems="center">
            <Col sm>{t("components.exportModal.workshift")}:</Col>
            <Col sm={9}>
              <Select
                value={formik.values.workShift}
                onChange={(event: React.ChangeEvent) =>
                  // @ts-ignore
                  formik.setFieldValue("workShift", event.target.value)
                }
              >
                <option value="">
                  {t(`components.exportModal.allWorkshifts`)}
                </option>
                {workshifts.map((w) => (
                  <option key={w} value={w}>
                    {t(`common.workshifts.${w}`)}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
          <HRLine my="5px" />
          <Row alignItems="center">
            <Col sm>{t("components.exportModal.partition")}:</Col>
            <Col sm={9}>
              <Select
                value={formik.values.partition}
                onChange={(event: React.ChangeEvent) =>
                  // @ts-ignore
                  formik.setFieldValue("partition", event.target.value)
                }
              >
                <option value="">
                  {t(`components.exportModal.noPartition`)}
                </option>
                {PLATE_PARTITION.map((partition, index) => (
                  <option key={index} value={partition}>
                    {t(`components.exportModal.partitionType.${partition}`)}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
          <HRLine my="5px" />
          <Row alignItems="center">
            <Col sm>{t("components.exportModal.fromDate")}:</Col>
            <Col sm={9}>
              <DatePicker
                // @ts-ignore
                dateFormat={"dd/MM/yyyy"}
                value={formik.values.fromDate.toDateString()}
                onChange={(value: any) => {
                  formik.setFieldValue("fromDate", new Date(value));

                  if (
                    dayjs(new Date(value)).isAfter(
                      dayjs(formik.values.toDate.toDateString())
                    )
                  ) {
                    formik.setFieldValue("toDate", new Date(value));
                  }
                }}
                disabled={
                  Number(formik.values.type) === OrderExportTypes.WeeklyMenu
                }
              />
            </Col>
          </Row>
          <HRLine my="5px" />
          <Row alignItems="center">
            <Col sm>{t("components.exportModal.toDate")}:</Col>
            <Col sm={9}>
              <DatePicker
                // @ts-ignore
                dateFormat={"dd/MM/yyyy"}
                value={formik.values.toDate.toDateString()}
                onChange={(value: any) =>
                  formik.setFieldValue("toDate", new Date(value))
                }
                disabled={
                  Number(formik.values.type) === OrderExportTypes.WeeklyMenu
                }
              />
            </Col>
          </Row>
          <HRLine my="5px" />
          <Row alignItems="center">
            <Col sm>{t("components.exportModal.exportType")}:</Col>
            <Col sm={9}>
              <Select
                value={formik.values.type}
                onChange={(event: React.ChangeEvent) =>
                  // @ts-ignore
                  formik.setFieldValue("type", event.target.value)
                }
              >
                {EXPORT_TYPES.map((op) => {
                  return (
                    <option
                      value={op.value}
                      disabled={
                        op.value === OrderExportTypes.WeeklyMenu &&
                        formik.values.company_ids.length === 0
                      }
                    >
                      {op.label}
                    </option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row paddingLeft={"1rem"} paddingRight={"1rem"} marginTop={"0.5rem"}>
            {formik.values.company_ids.length === 0 && (
              <p>{t(`components.exportModal.exportMenu`)}</p>
            )}
            {Number(formik.values.type) ===
              OrderExportTypes.UsersMonthlyAggregate && (
              <p>{t(`components.exportModal.aggregateMonthlyUsers`)}</p>
            )}
            {Number(formik.values.type) === OrderExportTypes.WeeklyMenu && (
              <p>{t(`components.exportModal.exportMenuDates`)}</p>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <div style={{ flex: 1 }}>
            <Button color="primary" onClick={handleSaveFilter}>
              {selectedFilter
                ? t("common.updateFilter")
                : t("common.saveFilter")}
            </Button>
            {selectedFilter && (
              <Button
                variant="outlined"
                color="danger"
                marginLeft={10}
                onClick={handleDeleteFilter}
              >
                {t("common.delete")}
              </Button>
            )}
          </div>
          <Button color="primary" type="submit">
            {t("common.confirm")}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
