import { FC } from "react";
import { StyledWrapper } from "./style";

const Wrapper: FC = ({ children }) => {
  // const { sidebar, isBody } = useAppSelector((state) => state.ui);
  const sidebar = true,
    isBody = true;
  return (
    <StyledWrapper
      $showSidebar={sidebar}
      $showBody={isBody}
      className="main-wrapper"
    >
      {children}
    </StyledWrapper>
  );
};

export default Wrapper;
