import styled, { device } from "../../../styled";

export const StyledWrap = styled.div`
  ${device.small} {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
`;

export const StyledRight = styled.div`
  display: flex;
  flex-direction: row;
`;
