import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../hooks/useStores";
import { useNavigate } from "react-router";

export const Logout: React.FC = observer(() => {
  const { session } = useStores();
  const navigate = useNavigate();
  useEffect(() => {
    session.logout();
    navigate("/");
  }, []);

  return null;
});
