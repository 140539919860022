import { observer } from "mobx-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DishForm from "../../components/Dishes/DishForm";
import Main from "../../components/Dishes/Main";
import { StyledBody } from "../../components/Dishes/Main/style";
import { useStores } from "../../hooks/useStores";
import { EditedDish } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const NewDishScreen: React.FC = observer(() => {
  const { dishes } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmitDish = useCallback(
    async (data: EditedDish) => {
      try {
        const newDish = await dishes.addDish(data);
        toast.success(t("messages.successes.dishForm.createCompleted"));
        navigate(`../${newDish.id}`);
      } catch (err) {
        console.log(err);
        toast.error(t("messages.errors.dishForm.generic"));
      }
    },
    [dishes, navigate, t]
  );

  return (
    <Main>
      <StyledBody>
        <DishForm
          dish={null}
          onSubmit={handleSubmitDish}
          dishCategories={dishes.dishCategories}
          plans={dishes.plans}
          allergens={dishes.allergens}
          dishes={dishes.dishes}
          dishTypologies={dishes.dishTypologies}
        />
      </StyledBody>
    </Main>
  );
});
