import i18n from "../../i18n";
import { BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stores } from "../../types";
import { SessionStore } from "../../stores/Session";
import { AppLoader } from "../../components/AppLoader";
import { StoresContext } from "../../contexts";
import { Navigation } from "./Navigation";
import { ToastContainer } from "react-toastify";
import { DishesStore } from "../../stores/DishesStore";
import { OrdersStore } from "../../stores/OrdersStore";
import { UsersStore } from "../../stores/UsersStore";
import { NewsStore } from "../../stores/NewsStore";
import { PushNotificationsStore } from "../../stores/PushNotificationsStore";
import dayjs from "dayjs";

require("dayjs/locale/it");

const Root: React.FC = observer(() => {
  const [stores] = useState<Stores>(() => {
    const root: Stores = {} as Stores;

    root.session = new SessionStore(root);
    root.dishes = new DishesStore(root);
    root.orders = new OrdersStore(root);
    root.users = new UsersStore(root);
    root.news = new NewsStore(root);
    root.pushNotifications = new PushNotificationsStore(root);

    return root;
  });

  useEffect(() => {
    i18n.changeLanguage("it");
    dayjs.locale("it");
  }, []);

  useEffect(() => {
    if (!stores.session.isInitialized) {
      stores.session.initialize();
    }
  }, []);

  if (!stores.session.isInitialized) {
    return <AppLoader />;
  }

  return (
    <StoresContext.Provider value={stores}>
      <Router>
        <Navigation />
      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        closeOnClick
      />
    </StoresContext.Provider>
  );
});

export default styled(Root)`
  & {
    height: 100vh;
  }
`;
