import styled from "../../styled";
import BackgroundImage from "../../assets/images/background.svg";

export const StyledContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-position: 0px 0px;
  background-image: url(${BackgroundImage});
  background-size: cover;
  object-fit: fill;
`;
