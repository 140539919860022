import axios, { AxiosRequestHeaders } from "axios";
import env from "../config/env";
import {
  Allergen,
  Company,
  Department,
  Dish,
  DishCategory,
  EditedDish,
  EditedFilter,
  EditedNews,
  EditedPNMessage,
  Filter,
  News,
  Order,
  OrderExportTypes,
  Plan,
  PNMessage,
  Role,
  StorageKey,
  User,
} from "../types";
import storageServices from "./storage";
import {
  toAllergen,
  toCompany,
  toDepartment,
  toDish,
  toDishCategory,
  toDishPayload,
  toFilter,
  toNews,
  toOrder,
  toPlan,
  toPNMessage,
  toRole,
  toUser,
  toUserFromLogin,
} from "./utils";
import dayjs from "dayjs";

let sessionToken: string | undefined;

const axiosClient = axios.create();
axiosClient.defaults.baseURL = env.BACKEND_API_URL;
axiosClient.defaults.timeout = 60000;

const getSessionToken = () => {
  return sessionToken;
};
const setSession = (token: string) => {
  sessionToken = token;
};

const getHeaders = (): AxiosRequestHeaders => {
  const accessToken: any = storageServices.getItem(StorageKey.SK_ACCESS_TOKEN);

  const headers: AxiosRequestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return headers;
};

axiosClient.interceptors.request.use(function (request) {
  request.headers = getHeaders();
  return request;
});

// Warning: Is the same of axiosSSOClient! Handling error 401
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // From axios github readme:
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const payload = error.response.data;
      console.log(payload);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }

    throw error;
  }
);

const login = async (email: string, password: string): Promise<User> => {
  const body = {
    identifier: email,
    password,
  };
  const response = await axiosClient.post("auth/local", body);

  storageServices.setItem(StorageKey.SK_ACCESS_TOKEN, response.data.jwt);
  setSession(response.data.jwt);

  return toUserFromLogin(response.data.user);
};

const forgotPassword = async (email: string): Promise<boolean> => {
  await axiosClient.post("auth/forgot-password", { email });

  return true;
};

const verifyPasswordResetCodeAndUpdatePassword = async (
  passwordResetCode: string,
  newPassword: string
) => {
  const response = await axiosClient.post("auth/reset-password", {
    code: passwordResetCode,
    password: newPassword,
    passwordConfirmation: newPassword,
  });

  return response.data;
};

const getCurrentUser = async (): Promise<User | null> => {
  const sessionData = storageServices.getItem(StorageKey.SK_ACCESS_TOKEN);
  if (!sessionData) return null;
  setSession(String(sessionData));
  const result = await axiosClient.get("users/me");

  if (result.data) return toUser(result.data);
  else return null;
};

const getUsers = async (): Promise<User[]> => {
  const result = await axiosClient.get(`users`);

  if (result.data?.data)
    return result.data.data.map((result: any) => {
      return toUser(result);
    });
  return [];
};

const createUser = async (user: {
  username: string;
  email: string;
  password: string;
  referenceOffice: string | null;
  companyId: number;
  roleId?: number;
  departmentId?: number;
  service: boolean;
  workshift: string | null;
}): Promise<User> => {
  const payload = {
    username: user.username,
    email: user.email,
    password: user.password,
    company: user.companyId,
    role: user.roleId,
    referenceOffice: user.referenceOffice,
    user_department: user.departmentId,
    service: user.service,
    workshift: user.workshift,
  };

  const result = await axiosClient.post(`users?populate=role`, payload);

  return toUser(result.data);
};

const updateUser = async (user: {
  id: number;
  username: string;
  email: string;
  password: string;
  referenceOffice: string | null;
  companyId: number;
  roleId?: number;
  departmentId?: number;
  service: boolean;
  workshift: string | null;
}): Promise<User | null> => {
  let payload: any = {
    username: user.username,
    email: user.email,
    company: user.companyId,
    role: user.roleId,
    referenceOffice: user.referenceOffice,
    user_department: user.departmentId,
    service: user.service,
    workshift: user.workshift,
  };

  if (user.password !== "") {
    payload.password = user.password;
  }

  const result = await axiosClient.put(`users/${user.id}`, payload);

  if (result.data) return toUser(result.data);
  return null;
};

const deleteUser = async (id: User["id"]): Promise<boolean> => {
  await axiosClient.delete(`users/${id}`);

  return true;
};

const logout = async () => {
  sessionToken = undefined;
};

const destroySession = () => {
  storageServices.removeItem(StorageKey.SK_ACCESS_TOKEN);
};

const fetchRoles = async (): Promise<Role[]> => {
  const response = await axiosClient.get("/users-permissions/roles");

  return response.data.roles
    .filter((role: any) => role.name !== "Public")
    .map((item: any) => toRole(item));
};

const fetchDepartments = async (): Promise<Department[]> => {
  const response = await axiosClient.get("/user-departments");

  return response.data.data.map((item: any) => toDepartment(item));
};

const fetchCompanies = async (): Promise<Company[]> => {
  const response = await axiosClient.get("/companies");

  return response.data.data.map((item: any) => toCompany(item));
};

const fetchPlans = async (): Promise<Plan[]> => {
  const response = await axiosClient.get("/plans");

  return response.data.data.map((item: any) => toPlan(item));
};

const fetchAllergens = async (): Promise<Allergen[]> => {
  const response = await axiosClient.get("/allergens");

  return response.data.data.map((item: any) => toAllergen(item));
};

const fetchDishCategories = async (): Promise<DishCategory[]> => {
  const response = await axiosClient.get("plate-categories");

  return response.data.data.map((item: any) => toDishCategory(item));
};

const fetchDishes = async (): Promise<Dish[]> => {
  const response = await axiosClient.get(
    "plates?populate=ingredients,plate_category,plans,allergens"
  );

  return response.data.data.map((item: any) => toDish(item));
};

const fetchDishTypologies = async (): Promise<string[]> => {
  const response = await axiosClient.get(
    "content-type-builder/content-types/api::plate.plate"
  );

  return response.data.data.schema.attributes.typology.enum;
};

const addDish = async (data: EditedDish): Promise<Dish> => {
  const payload = toDishPayload(data);

  const response = await axiosClient.post(
    "plates?populate=ingredients,plate_category,plans,allergens",
    { data: payload }
  );

  return toDish(response.data.data);
};

const updateDish = async (id: Dish["id"], data: EditedDish): Promise<Dish> => {
  const payload = toDishPayload(data);

  const response = await axiosClient.put(
    `plates/${id}?populate=ingredients,plate_category,plans,allergens`,
    { data: payload }
  );

  return toDish(response.data.data);
};

const deleteDish = async (id: Dish["id"]): Promise<boolean> => {
  await axiosClient.delete(`plates/${id}`);

  return true;
};

const fetchOrders = async (
  fromDate: Date,
  toDate: Date,
  companyes?: Company[]
): Promise<Order[]> => {
  let companyQueryString = "";
  if (companyes && companyes.length > 0) {
    companyQueryString += `&companyId=${companyes.map((c) => c.id).join(",")}`;
  }

  const response = await axiosClient.get(
    `orders?startDate=${dayjs(fromDate).format("YYYY-MM-DD")}&endDate=${dayjs(
      toDate
    ).format("YYYY-MM-DD")}${
      companyQueryString ? `${companyQueryString}` : ""
    }&platesPopulate=plate_category,allergens`
  );

  return response.data.data.map((item: any) => toOrder(item));
};

const fetchNews = async (): Promise<News[]> => {
  const response = await axiosClient.get("news-articles");

  return response.data.data.map((item: any) => toNews(item));
};

const addNews = async (data: EditedNews): Promise<News> => {
  const response = await axiosClient.post("news-articles", { data });

  return toNews(response.data.data);
};

const updateNews = async (id: News["id"], data: EditedNews): Promise<News> => {
  const response = await axiosClient.put(`news-articles/${id}`, { data });

  return toNews(response.data.data);
};

const deleteNews = async (id: News["id"]): Promise<boolean> => {
  await axiosClient.delete(`news-articles/${id}`);

  return true;
};

const exportOrders = async (data: any[][]): Promise<Blob> => {
  const response = await axiosClient.post(
    "export-orders",
    { data },
    {
      responseType: "blob",
      timeout: 60000,
    }
  );

  return response.data;
};

const fetchPushNotifications = async (): Promise<PNMessage[]> => {
  const response = await axiosClient.get("pn-messages?populate=company");

  return response.data.data.map((item: any) => toPNMessage(item));
};

const addPNMessage = async (data: EditedPNMessage): Promise<PNMessage> => {
  const response = await axiosClient.post(
    "pn-messages/send-notification?populate=company",
    { data }
  );

  return toPNMessage(response.data.data);
};

const getFilters = async (userId: number): Promise<Filter[]> => {
  const result = await axiosClient.get(
    `order-filters?userId=${userId}&populate=company_ids`
  );

  return result.data.data.map((result: any) => toFilter(result));
};

const createFilter = async (filter: EditedFilter): Promise<Filter> => {
  const result = await axiosClient.post(`order-filters?populate=company_ids`, {
    data: filter,
  });

  return toFilter(result.data.data);
};

const updateFilter = async (filter: EditedFilter): Promise<Filter | null> => {
  const result = await axiosClient.put(
    `order-filters/${filter.id}?populate=company_ids`,
    {
      data: filter,
    }
  );

  if (result.data) return toFilter(result.data.data);
  return null;
};

const deleteFilter = async (id: EditedFilter["id"]): Promise<boolean> => {
  await axiosClient.delete(`order-filters/${id}`);
  return true;
};

const api = {
  login,
  forgotPassword,
  verifyPasswordResetCodeAndUpdatePassword,
  getSessionToken,
  getCurrentUser,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  logout,
  destroySession,
  fetchRoles,
  fetchDepartments,
  fetchCompanies,
  fetchPlans,
  fetchAllergens,
  fetchDishes,
  fetchDishTypologies,
  fetchDishCategories,
  addDish,
  updateDish,
  deleteDish,
  fetchOrders,
  fetchNews,
  addNews,
  updateNews,
  deleteNews,
  exportOrders,
  fetchPushNotifications,
  addPNMessage,
  getFilters,
  createFilter,
  updateFilter,
  deleteFilter,
};

export default api;
