import Label from "../Label";
import { User, UserSection } from "../../../../types";
import { useMemo } from "react";
import UserItem from "../UserItem";

type Props = {
  users: User[];
  activeId?: number;
  clickHandler: (user: User) => void;
  disableSections?: boolean;
};

const AllUsers: React.FC<Props> = ({
  users,
  activeId,
  clickHandler,
  disableSections,
}: Props) => {
  const sections: UserSection[] = useMemo(() => {
    if (!users) return [];
    let toReturn: UserSection[] = [];

    //Sort the items by their primaryText
    let tempItems = [...users];
    tempItems.sort((a, b) => {
      let fa = a.username.toLowerCase(),
        fb = b.username.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    //Create a section for every new initial letter
    let currentLetter = "";
    tempItems.map((item) => {
      if (item.username[0] !== currentLetter || currentLetter === "") {
        toReturn.push({ label: item.username[0], items: [] });
        currentLetter = item.username[0];
      }
      return toReturn[toReturn.length - 1].items.push(item);
    });

    return toReturn;
  }, [users]);

  return (
    <>
      {disableSections ? (
        users?.map((item, index) => (
          <UserItem
            id={index}
            user={item}
            activeId={activeId}
            onClick={() => clickHandler(item)}
            key={item.id}
          />
        ))
      ) : (
        <>
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <Label size="lg">{section.label}</Label>
              {section.items.map((item, itemIndex) => (
                <UserItem
                  id={item.id}
                  user={item}
                  activeId={activeId}
                  onClick={() => clickHandler(item)}
                  key={item.id}
                />
              ))}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default AllUsers;
