import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Main from "../../components/Dishes/Main";
import UserForm from "../../components/Users/UserForm";
import { useStores } from "../../hooks/useStores";
import { EditedUSer, User } from "../../types";

export const EditUserScreen: React.FC = observer(() => {
  const { users } = useStores();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useMemo(() => {
    const id = Number(params.userId);

    return users.users.find((item) => item.id === id);
  }, [params, users.users]);

  const handleSubmit = useCallback(
    async (data: EditedUSer) => {
      try {
        if (data.id) {
          await users.updateUser(data);

          toast.success(t("messages.successes.dishForm.updateCompleted"));
        }
      } catch (err) {
        console.log(err);
        toast.error(t("messages.errors.dishForm.generic"));
      }
    },
    [users, t]
  );

  const handleDelete = useCallback(
    async (userId: User["id"]) => {
      if (window.confirm(t("components.userForm.confirmDelete"))) {
        try {
          await users.deleteUser(userId);
          toast.success(t("messages.successes.updateUserForm.deleteCompleted"));
          navigate(`..`);
        } catch (err) {
          console.log(err);
          toast.error(t("messages.errors.userForm.generic"));
        }
      }
    },
    [users, navigate, t]
  );

  return (
    <Main>
      <UserForm
        user={user}
        onSubmit={handleSubmit}
        roles={users.roles}
        companies={users.companies}
        isSubmitting={users.isSubmitting}
        onCancel={handleDelete}
        departments={users.departments}
      />
    </Main>
  );
});
