import styled, { themeGet } from "../../../styled";
import { Media } from "../../UI/Media";

export const StyledHeader = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 0.8rem;
    margin-bottom: 0;
    color: ${themeGet("colors.gray700")};
  }
`;

export const StyledMedia = styled(({ ...rest }) => <Media {...rest} />)`
  color: ${themeGet("colors.text2")};
  margin-bottom: 0.75rem;
  align-items: center;
  &:not(:first-of-type) {
    margin-top: 2px;
  }
`;
