import { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { AbilityContext } from "../../components/Common/Can";
import Content from "../../components/Common/Content";

export const Home: React.FC = () => {
  const ability: any = useContext(AbilityContext);

  const isAuthorized = useMemo(() => {
    return ability.can("read", "orders");
  }, [ability]);

  return (
    <Content>{isAuthorized ? <Navigate to="/app/orders" /> : null}</Content>
  );
};
