import { FC } from "react";
import { Button } from "../../../Common/Button";
import SearchForm from "../SearchForm";
import { StyledHeader } from "./style";
import { HiOutlinePlus, HiOutlineSearch } from "react-icons/hi";

type Props = {
  searchedText: string;
  setSearchedText: (text: string) => void;
  onAddClick: () => void;
};

const SidebarHeader: FC<Props> = ({
  searchedText,
  setSearchedText,
  onAddClick,
}: Props) => {
  return (
    <StyledHeader>
      <HiOutlineSearch className="search" />
      <SearchForm
        searchedText={searchedText}
        setSearchedText={setSearchedText}
      />
      <Button size={"md"} iconButton onClick={onAddClick}>
        <HiOutlinePlus />
      </Button>
    </StyledHeader>
  );
};

export default SidebarHeader;
