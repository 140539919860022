import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Flex } from "rebass";
import Wrapper from "../../components/Dishes/Wrapper";
import Sidebar from "../../components/Dishes/Sidebar";
import { useStores } from "../../hooks/useStores";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Dish } from "../../types";
import useDebounce from "../../hooks/useDebounce";
import { fuzzyMatch } from "../../utils/string";
import { useTranslation } from "react-i18next";

export const DishesLayout: React.FC = observer(() => {
  const { dishes: dishesStore } = useStores();
  const navigate = useNavigate();
  const params = useParams();
  const [search, setSearch] = useState("");
  const debouncedQuery = useDebounce(search, 300);
  const { i18n } = useTranslation();

  useEffect(() => {
    dishesStore.fetchDishes();
    dishesStore.fetchDishTypologies();
    dishesStore.fetchDishCategories();
    dishesStore.fetchAllergens();
    dishesStore.fetchPlans();
  }, []);

  const activeDishId = useMemo(() => {
    return params.dishId ? Number(params.dishId) : undefined;
  }, [params]);

  const filteredDishes = useMemo(() => {
    return debouncedQuery.length > 0
      ? dishesStore.dishes.filter((dish) =>
          fuzzyMatch(dish.name[i18n.language] || "No-name", debouncedQuery)
        )
      : dishesStore.dishes;
  }, [debouncedQuery, dishesStore.dishes, i18n.language]);

  const handleAddClick = () => {
    navigate("./new");
  };

  const handleDishClick = (dish: Dish) => {
    navigate(`./${dish.id}`);
  };

  return (
    <Flex padding={"3%"} flexDirection={"column"}>
      <Wrapper>
        <Sidebar
          dishes={filteredDishes}
          activeId={activeDishId}
          clickHandler={handleDishClick}
          onAddClick={handleAddClick}
          searchedText={search}
          onSearchChange={setSearch}
        />
        <Outlet />
      </Wrapper>
    </Flex>
  );
});
