import { FC, MouseEvent } from "react";
import { HiOutlineEye, HiOutlineEyeOff,  } from "react-icons/hi";
import { Dish } from "../../../../types";
import { MediaBody } from "../../Media";
import { StyledMedia, StyledName, StyledSpan,  } from "./style";

interface IProps {
  id: Dish["id"];
  dish: Dish;
  activeId?: number;
  locale: string;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const ContactItem: FC<IProps> = ({ id, dish, activeId, locale, onClick }) => {
  return (
    <StyledMedia
      id={id}
      $active={activeId === id}
      onClick={onClick}
      style={{ alignItems: "center" }}
    >
      {dish.visible ?  <HiOutlineEye /> : <HiOutlineEyeOff /> }
      <MediaBody ml="10px">
        <StyledName>{dish.name[locale]}</StyledName>
        <StyledSpan>{dish.subname[locale]}</StyledSpan>
      </MediaBody>
    </StyledMedia>
  );
};

export default ContactItem;
