import React from "react";
import styled from "styled-components";
import { Button as RebassButton } from "rebass/styled-components";
import { transparentize } from "polished";
import { useTranslation } from "react-i18next";
import { Flex } from "rebass";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { themeGet } from "../../../styled";
import tinycolor2 from "tinycolor2";

type CalendarNavButtonsProps = {
  goBack: () => void;
  goNext: () => void;
  goToday: () => void;
};

export const CalendarNavButtons: React.FC<CalendarNavButtonsProps> = ({
  goBack,
  goNext,
  goToday,
}) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <PrevButton onClick={goBack}>
        <HiChevronLeft />
      </PrevButton>
      <TodayButton onClick={goToday}>
        {t("components.calendar.today")}
      </TodayButton>
      <NextButton onClick={goNext}>
        <HiChevronRight />
      </NextButton>
    </Flex>
  );
};

const TodayButton = styled(RebassButton)`
  padding: 0.75rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: #d0111d;
  box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
  z-index: 1;
`;

const PrevButton = styled(RebassButton)`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => transparentize(0.1, "#D0111D")};
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  margin-right: -0.5rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: #fff;
`;

const NextButton = styled(RebassButton)`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => transparentize(0.1, "#D0111D")};
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: -0.5rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: #fff;
`;
