import { FC, useMemo } from "react";
import { AlertMessage, Company, Department, Role, User } from "../../../types";
import { StyledLabelWrap, StyledHeader, StyledBody } from "./style";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, Label } from "../../Common/Forms";
import { Button } from "../../Common/Button";
import { useFormik } from "formik";
import { CustomSelect } from "../../Common/Forms/CustomSelect";
import ScrollBar from "../../Common/Scrollbar";
import { Col, Row } from "../../Common/Grid";
import { Checkbox } from "../../Common/Forms/Checkbox";
import workshifts from "../../../config/workshifts";

type Props = {
  message?: AlertMessage;
  isSubmitting: boolean;
  user?: User;
  companies: Company[];
  roles: Role[];
  departments: Department[];
  onSubmit: (user: {
    id?: number;
    username: string;
    email: string;
    referenceOffice: string | null;
    password: string;
    companyId: number;
    roleId?: number;
    departmentId?: number;
    service: boolean;
    workshift: string | null;
  }) => any;
  onCancel?: (id: number) => any;
};

const UserForm: FC<Props> = ({
  message,
  isSubmitting,
  user,
  onSubmit,
  onCancel,
  companies,
  roles,
  departments,
}) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      id: user?.id || undefined,
      username: user?.username || "",
      email: user?.email || "",
      referenceOffice: user?.referenceOffice || "",
      companyId: user?.company?.id || 0,
      roleId: user?.role?.id || 1,
      password: "",
      confirmPassword: "",
      departmentId: user?.department?.id || undefined,
      service: user?.service || false,
      workshift: user?.workshift || "",
    },
    enableReinitialize: true,
    onSubmit,
  });

  const workshiftsOptions = useMemo(
    () =>
      [{ value: "", label: "-" }].concat(
        workshifts.map((wId) => ({
          value: wId,
          label: t(`common.workshifts.${wId}`),
        }))
      ),
    [t]
  );

  const selectedWorkshiftOption = useMemo(() => {
    return formik.values.workshift
      ? {
          value: formik.values.workshift,
          label: t(`common.workshifts.${formik.values.workshift}`),
        }
      : { value: "", label: "" };
  }, [formik.values.workshift, t]);

  const companyOptions = useMemo(() => {
    return companies.map((company) => ({
      value: company.id,
      label: company.name,
    }));
  }, [companies]);

  const selectedCompanyOption = useMemo(() => {
    const company = companies.find((p) => p.id === formik.values.companyId);

    return {
      value: company?.id,
      label: company?.name,
    };
  }, [formik.values.companyId, companies]);

  const roleOptions = useMemo(() => {
    return roles.map((role) => ({
      value: role.id,
      label: role.name,
    }));
  }, [roles]);

  const selectedRoleOption = useMemo(() => {
    const role = roles.find((p) => p.id === formik.values.roleId);

    return {
      value: role?.id,
      label: role?.name,
    };
  }, [formik.values.roleId, roles]);

  const departmentOptions = useMemo(() => {
    return departments.map((department) => ({
      value: department.id,
      label: department.department,
    }));
  }, [departments]);

  const selectedDepartmentOption = useMemo(() => {
    const department = departments.find(
      (p) => p.id === formik.values.departmentId
    );

    return {
      value: department?.id,
      label: department?.department,
    };
  }, [formik.values.departmentId, departments]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
      <StyledHeader>
        {onCancel && user && (
          <Button
            type="submit"
            variant="texted"
            onClick={() => onCancel(user.id)}
            marginRight="1.5rem"
            disabled={isSubmitting}
          >
            {t("common.delete")}
          </Button>
        )}
        <Button type="submit" variant="outlined">
          {t("common.save")}
        </Button>
      </StyledHeader>

      <ScrollBar top="55px">
        <StyledBody style={{ paddingBottom: "10em" }}>
          <FormGroup mb="20px">
            <Label display="block" mb="5px" htmlFor="email">
              {t("components.userForm.email")}
            </Label>
            <Input
              type="email"
              id="email"
              name={"email"}
              placeholder="nome@email.com"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
          </FormGroup>
          <FormGroup mb="20px">
            <Label display="block" mb="5px" htmlFor="username">
              {t("components.userForm.username")}
            </Label>
            <Input
              type="text"
              id="username"
              name={"username"}
              placeholder={t("components.userForm.username")}
              value={formik.values.username}
              onChange={(e) => formik.setFieldValue("username", e.target.value)}
            />
          </FormGroup>
          <FormGroup mb="20px">
            <StyledLabelWrap>
              <Label display="block" mb="5px" htmlFor="password">
                {t("common.password")}
              </Label>
            </StyledLabelWrap>
            <Input
              id="password"
              type="password"
              placeholder={t("common.password")}
              name={"password"}
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue("password", e.target.value)}
            />
          </FormGroup>

          <FormGroup mb="20px">
            <StyledLabelWrap>
              <Label display="block" mb="5px" htmlFor="confirmPassword">
                {t("components.userForm.confirmPassword")}
              </Label>
            </StyledLabelWrap>
            <Input
              id="confirmPassword"
              type="password"
              placeholder={t("components.userForm.confirmPassword")}
              name={"confirmPassword"}
              value={formik.values.confirmPassword}
              onChange={(e) =>
                formik.setFieldValue("confirmPassword", e.target.value)
              }
            />
          </FormGroup>

          <FormGroup mb="20px">
            <StyledLabelWrap>
              <Label display="block" mb="5px" htmlFor="company">
                {t("components.userForm.company")}
              </Label>
            </StyledLabelWrap>
            <CustomSelect
              options={companyOptions}
              value={selectedCompanyOption}
              placeholder={t("components.userForm.company")}
              onChange={(option: unknown) => {
                formik.setFieldValue(
                  "companyId",
                  (option as { value: number; label: string }).value
                );
              }}
            />
          </FormGroup>
          <FormGroup mb="20px">
            <StyledLabelWrap>
              <Label display="block" mb="5px" htmlFor="role">
                {t("components.userForm.role")}
              </Label>
            </StyledLabelWrap>
            <CustomSelect
              options={roleOptions}
              value={selectedRoleOption}
              onChange={(option: unknown) => {
                formik.setFieldValue(
                  "roleId",
                  (option as { value: number; label: string }).value
                );
              }}
            />
          </FormGroup>

          <FormGroup mb="20px">
            <Label display="block" mb="5px" htmlFor="referenceOffice">
              {t("components.userForm.referenceOffice")}
            </Label>
            <Input
              type="text"
              id="referenceOffice"
              name="referenceOffice"
              placeholder={t("components.userForm.referenceOffice")}
              value={formik.values.referenceOffice}
              onChange={(e) =>
                formik.setFieldValue("referenceOffice", e.target.value)
              }
            />
          </FormGroup>

          <FormGroup mb="20px">
            <StyledLabelWrap>
              <Label display="block" mb="5px" htmlFor="role">
                {t("components.userForm.department")}
              </Label>
            </StyledLabelWrap>
            <CustomSelect
              options={departmentOptions}
              value={selectedDepartmentOption}
              placeholder={t("components.userForm.department")}
              onChange={(option: unknown) => {
                formik.setFieldValue(
                  "departmentId",
                  (option as { value: number; label: string }).value
                );
              }}
            />
          </FormGroup>

          <FormGroup mb="20px">
            <StyledLabelWrap>
              <Label display="block" mb="5px" htmlFor="workshift">
                {t("components.userForm.workshift")}
              </Label>
            </StyledLabelWrap>
            <CustomSelect
              options={workshiftsOptions}
              value={selectedWorkshiftOption}
              placeholder={t("components.userForm.workshift")}
              onChange={(option: unknown) => {
                formik.setFieldValue(
                  "workshift",
                  (option as { value: number; label: string }).value
                );
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="service">{t("components.userForm.service")}</Label>
            <Row alignItems="center">
              <Col sm={10}>
                <Checkbox
                  id="service"
                  checked={formik.values.service}
                  label={
                    formik.values.service
                      ? t("components.userForm.serviceLabel")
                      : t("components.userForm.notServiceLabel")
                  }
                  {...formik.getFieldProps("service")}
                />
              </Col>
            </Row>
          </FormGroup>
        </StyledBody>
      </ScrollBar>
    </form>
  );
};

export default UserForm;
