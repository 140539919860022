import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyledForm, StyledInput } from "./style";

type Props = {
  searchedText: string;
  setSearchedText: (text: string) => void;
};

const SearchForm: FC<Props> = ({ searchedText, setSearchedText }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <StyledInput
        id="dishes-search"
        name="dishes-search"
        value={searchedText}
        onChange={(e: any) => {
          setSearchedText(e.target.value);
        }}
        placeholder={t("components.dishes.search")}
      />
    </StyledForm>
  );
};

export default SearchForm;
