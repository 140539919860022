import { observer } from "mobx-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../components/Dishes/Main";
import { StyledBody } from "../../components/Dishes/Main/style";
import { useStores } from "../../hooks/useStores";
import { EditedNews } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import NewsForm from "../../components/News/NewsForm";

export const NewNewsScreen: React.FC = observer(() => {
  const { news } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: EditedNews) => {
      try {
        const newNews = await news.addNews(data);
        toast.success(t("messages.successes.newsForm.createCompleted"));
        navigate(`../${newNews.id}`);
      } catch (err) {
        console.log(err);
        toast.error(t("messages.errors.newsForm.generic"));
      }
    },
    [news, navigate, t]
  );

  return (
    <Main>
      <StyledBody>
        <NewsForm news={null} onSubmit={handleSubmit} />
      </StyledBody>
    </Main>
  );
});
