import { observer } from "mobx-react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AppLayout } from "../AppLayout";
import { Logout } from "../Logout";
import { PrivateRoute } from "../../components/PrivateRoute";
import { SignInScreen } from "../SignIn";
import { ForgotPasswordScreen } from "../ForgotPassword";
import { ChangePasswordScreen } from "../ChangePassword";
import { RedirectLoggedUser } from "../RedirectLoggedUser";
import { Home } from "../Home";
import { Users } from "../Users";
import { DishesLayout } from "../DishesLayout";
import { NewDishScreen } from "../NewDish";
import { EditDishScreen } from "../EditDish";
import { OrdersScreen } from "../Orders";
import { News } from "../News";
import { NewsDetail } from "../NewsDetail";
import { NewNewsScreen } from "../NewNews";
import { EditNewsScreen } from "../EditNews";
import { NewUserScreen } from "../NewUser";
import { EditUserScreen } from "../EditUser";
import { PNMessages } from "../PNMessages";
import { PNMessageDetail } from "../PNMessageDetail";
import { NewPNMessageScreen } from "../NewPNMessage";
import { SatisfactionScreen } from "../Satisfaction";

export const Navigation: React.FC = observer(() => {
  return (
    <Routes>
      <Route path="/login" element={<SignInScreen />} />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/change-password" element={<ChangePasswordScreen />} />
      <Route path="/logout" element={<Logout />} />
      <Route element={<PrivateRoute />}>
        <Route element={<AppLayout />}>
          <Route path="app">
            <Route index element={<Home />} />
            <Route path="users/*" element={<Users />}>
              <Route path="new" element={<NewUserScreen />} />
              <Route path=":userId" element={<EditUserScreen />} />
            </Route>
            <Route path="satisfaction" element={<SatisfactionScreen />} />
            <Route path="dishes/*" element={<DishesLayout />}>
              <Route path="new" element={<NewDishScreen />} />
              <Route path=":dishId" element={<EditDishScreen />} />
            </Route>
            <Route path="orders" element={<OrdersScreen />} />
            <Route path="news/*" element={<News />}>
              <Route path="new" element={<NewNewsScreen />} />
              <Route path=":newsId" element={<NewsDetail />} />
              <Route path=":newsId/edit" element={<EditNewsScreen />} />
            </Route>
            <Route path="push-notifications/*" element={<PNMessages />}>
              <Route path="new" element={<NewPNMessageScreen />} />
              <Route path=":messageId" element={<PNMessageDetail />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<RedirectLoggedUser />} />
    </Routes>
  );
});
