import { FC } from "react";
import { ArrowLeft } from "react-feather";
import ScrollBar from "../../Common/Scrollbar";
import { StyledMain, StyledHeader, StyledBody, StyledToggleBtn } from "./style";
import MailBody from "../MailBody";
import { Spinner } from "../../Common/Spinner";
import { News } from "../../../types";
import ToolbarIcons from "../ToolbarIcons";

type Props = {
  news: News | null;
  isLoading?: boolean;
  onDelete: (id: News["id"]) => any;
  onEdit: (id: News["id"]) => any;
};

const Main: FC<Props> = ({ news, isLoading = false, onDelete, onEdit }) => {
  const bodyHandler = () => {
    // dispatch(toggleBody());
  };

  return (
    <StyledMain>
      <StyledHeader>
        <StyledToggleBtn type="button" onClick={bodyHandler}>
          <ArrowLeft />
        </StyledToggleBtn>
        {isLoading ? <Spinner /> : null}
        {news && (
          <ToolbarIcons
            onDelete={() => onDelete(news.id)}
            onEdit={() => onEdit(news.id)}
          />
        )}
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          {!isLoading && news && <MailBody news={news} />}
        </StyledBody>
      </ScrollBar>
    </StyledMain>
  );
};

export default Main;
