import styled from "../../../../styled";

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 0 0 0 40px;

  span {
    margin-right: 0.325rem;
    font-weight: 500;
    color: ${(props) => props.color};
    font-size: 0.875rem;
  }
`;
