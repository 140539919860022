import styled, { themeGet } from "../../../styled";
import { Input } from "../../Common/Forms";

export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  input {
    &:hover,
    &:focus,
    &:active {
      border: none;
      box-shadow: none;
    }
  }
`;

export const StyledInput = styled(({ ...rest }) => <Input {...rest} />)`
  border-width: 0;
  background-color: transparent;
  font-size: inherit;
  padding: 0;
  color: ${themeGet("colors.text2")};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
`;
