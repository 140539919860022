import { FC, useState } from "react";
import { MoreVertical, Edit3, Trash } from "react-feather";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Nav, NavLink } from "../../Common/Nav/nav";
import { StyledToolbar } from "./style";

type Props = {
  onDelete: () => void;
  onEdit: () => void;
};

const ToolbarIcons: FC<Props> = ({ onDelete, onEdit }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleNav = () => {
    setShow((prev) => !prev);
  };

  return (
    <StyledToolbar $show={show}>
      <Nav customStyle="icon">
        <NavLink
          path="#"
          data-for="tooltip-delete"
          data-tip={t("components.news.delete")}
          onClick={(e: any) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <Trash />
        </NavLink>
        <NavLink
          path="#"
          data-for="tooltip-edit"
          data-tip={t("components.news.edit")}
          onClick={(e: any) => {
            e.preventDefault();
            onEdit();
          }}
        >
          <Edit3 />
        </NavLink>
        <ReactTooltip place="bottom" effect="solid" id="tooltip-edit" />
        <ReactTooltip place="bottom" effect="solid" id="tooltip-delete" />
      </Nav>
      <button type="button" className="option-btn" onClick={handleNav}>
        <MoreVertical />
      </button>
    </StyledToolbar>
  );
};

export default ToolbarIcons;
