import { RawAbility, RoleName } from "../types";

const ROLES_ACLS: { [key in RoleName]: RawAbility[] } = {
  Administrator: [
    { subject: "dishes", action: ["read", "create", "edit", "delete"] },
    { subject: "orders", action: ["read"] },
    { subject: "users", action: ["edit", "delete", "read", "create"] },
    { subject: "satisfaction", action: ["read"] },
    { subject: "news", action: ["delete", "create", "read", "edit"] },
    {
      subject: "push-notifications",
      action: ["read", "create", "edit", "delete"],
    },
  ],
  Kitchen: [{ subject: "orders", action: ["read"] }],
  Authenticated: [],
  OrganizationAdmin: [
    { subject: "orders", action: ["read"] },
    { subject: "users", action: ["edit", "delete", "read", "create"] },
  ],
};

export default ROLES_ACLS;
