import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { EditedPNMessage, PNMessage } from "../../../types";
import { Button } from "../../Common/Button";
import { Input, Label } from "../../Common/Forms";
import ScrollBar from "../../Common/Scrollbar";
import { StyledHeader, StyledBody, FormControl } from "./style";
import * as Yup from "yup";
import Feedback from "../../Common/Forms/Feedback";

type Props = {
  message: PNMessage | null;
  onSubmit: (news: EditedPNMessage) => any;
};

const PNMessageForm: React.FC<Props> = ({ message, onSubmit }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      id: message?.id || undefined,
      title: message?.title || "",
      description: message?.description || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(1, t("messages.formValidations.pnMessageForm.titleRequired"))
        .max(64, t("messages.formValidations.pnMessageForm.titleRequired"))
        .required(t("messages.formValidations.pnMessageForm.titleRequired")),
      description: Yup.string()
        .min(1, t("messages.formValidations.pnMessageForm.descriptionRequired"))
        .max(
          178,
          t("messages.formValidations.pnMessageForm.descriptionRequired")
        )
        .required(
          t("messages.formValidations.pnMessageForm.descriptionRequired")
        ),
    }),
    enableReinitialize: true,
    onSubmit: (values: EditedPNMessage) => {
      if (window.confirm(t("components.pnMessageForm.confirmSendMessage"))) {
        onSubmit(values);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
      <StyledHeader>
        <Button type="submit" variant="outlined">
          {t("components.pnMessageForm.send")}
        </Button>
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <FormControl>
            <Label htmlFor="title">{t("components.pnMessageForm.title")}</Label>
            <Input
              placeholder={t("components.pnMessageForm.title")}
              px="0px"
              id={"title"}
              {...formik.getFieldProps("title")}
            />
            {formik.touched.title && formik.errors.title && (
              <Feedback state={"error"} showState={true} showErrorOnly>
                {formik.errors.title}
              </Feedback>
            )}
          </FormControl>

          <FormControl>
            <Label htmlFor="content">
              {t("components.pnMessageForm.description")}
            </Label>
            <Input
              placeholder={t("components.pnMessageForm.description")}
              px="0px"
              id={"description"}
              {...formik.getFieldProps("description")}
            />
            {formik.touched.description && formik.errors.description && (
              <Feedback state={"error"} showState={true} showErrorOnly>
                {formik.errors.description}
              </Feedback>
            )}
          </FormControl>
        </StyledBody>
      </ScrollBar>
    </form>
  );
};

export default PNMessageForm;
