import { observer } from "mobx-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../components/Dishes/Main";
import { StyledBody } from "../../components/Dishes/Main/style";
import { useStores } from "../../hooks/useStores";
import { EditedPNMessage } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PNMessageForm from "../../components/PNMessages/PNMessageForm";

export const NewPNMessageScreen: React.FC = observer(() => {
  const { pushNotifications } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: EditedPNMessage) => {
      try {
        const newNews = await pushNotifications.addPNMessage(data);
        toast.success(t("messages.successes.newsForm.createCompleted"));
        navigate(`../${newNews.id}`);
      } catch (err) {
        console.log(err);
        toast.error(t("messages.errors.newsForm.generic"));
      }
    },
    [pushNotifications, t, navigate]
  );

  return (
    <Main>
      <StyledBody>
        <PNMessageForm message={null} onSubmit={handleSubmit} />
      </StyledBody>
    </Main>
  );
});
