import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import Main from "../../components/News/Main";
import { News } from "../../types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const NewsDetail: React.FC = () => {
  const { news } = useStores();
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activeNewsId = useMemo(() => {
    return Number(params.newsId);
  }, [params]);

  const activeNews = useMemo(() => {
    return activeNewsId
      ? news.news.find((item) => item.id === activeNewsId)
      : null;
  }, [activeNewsId, news.news]);

  const handleDelete = useCallback(
    async (id: News["id"]) => {
      if (window.confirm(t("components.newsForm.confirmDelete"))) {
        try {
          await news.deleteNews(id);
          toast.success(t("messages.successes.dishForm.deleteCompleted"));
          navigate(`..`);
        } catch (err) {
          console.log(err);
          toast.error(t("messages.errors.dishForm.generic"));
        }
      }
    },
    [news, navigate, t]
  );

  const handleEdit = useCallback(
    async (id: News["id"]) => {
      navigate(`./edit`);
    },
    [navigate]
  );

  return activeNews ? (
    <Main
      news={activeNews}
      isLoading={news.isFetching}
      onDelete={handleDelete}
      onEdit={handleEdit}
    />
  ) : null;
};
