import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button";
import Breadcrumb from "../Breadcrumb";
import { CalendarNavButtons } from "../CalendarNavButtons";

import { StyledRight, StyledWrap } from "./style";

interface IProps {
  /**
   * Pass previous page's text and link
   */
  prev: Array<{
    text: string;
    link: string;
  }>;
  /**
   * Write page's title
   */
  title: string;
  /**
   * Write page's welcome text
   */
  wcText?: string;
  goBack: () => void;
  goNext: () => void;
  goToday: () => void;
  onExportClick?: () => void;
}

const PageHeader: FC<IProps> = ({
  prev,
  title,
  wcText,
  goBack,
  goNext,
  goToday,
  onExportClick,
}) => {
  const { t } = useTranslation();
  return (
    <StyledWrap>
      <div>
        <Breadcrumb prev={prev} title={title} wcText={wcText} />
      </div>
      <StyledRight>
        {onExportClick && (
          <div style={{ marginRight: "1rem", paddingTop: "0.2rem" }}>
            <Button onClick={onExportClick} variant="outlined">
              {t("components.orders.reportsBtt")}
            </Button>
          </div>
        )}
        <CalendarNavButtons goBack={goBack} goNext={goNext} goToday={goToday} />
      </StyledRight>
    </StyledWrap>
  );
};

export default PageHeader;
