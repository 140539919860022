import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyledForm, StyledInput } from "./style";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const SearchForm: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <StyledInput
        id="search"
        name="search"
        placeholder={t("components.news.searchPlaceholder")}
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
      />
    </StyledForm>
  );
};

export default SearchForm;
