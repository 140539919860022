import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LOCALES } from "../../../config/locale";
import { EditedNews, News } from "../../../types";
import { Button } from "../../Common/Button";
import { Input, Label } from "../../Common/Forms";
import { Col, Row } from "../../Common/Grid";
import ScrollBar from "../../Common/Scrollbar";
import { StyledHeader, StyledBody, FormControl } from "./style";

type Props = {
  news: News | null;
  onSubmit: (news: EditedNews) => any;
  onCancel?: (newsId: News["id"]) => any;
};

const NewsForm: React.FC<Props> = ({ news, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      id: news?.id || undefined,
      title:
        news?.title ||
        LOCALES.reduce((list: any, item) => {
          list[item] = "";
          return list;
        }, {}),
      content:
        news?.content ||
        LOCALES.reduce((list: any, item) => {
          list[item] = "";
          return list;
        }, {}),
    },
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
      <StyledHeader>
        {onCancel && news && (
          <Button
            type="button"
            variant="texted"
            onClick={() => onCancel(news.id)}
            marginRight="1.5rem"
          >
            {t("common.delete")}
          </Button>
        )}

        <Button type="submit" variant="outlined">
          {t("common.save")}
        </Button>
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <FormControl>
            <Label htmlFor="title">{t("components.newsForm.title")}</Label>
            {LOCALES.map((locale) => (
              <Row alignItems="center">
                <Col sm>{t(`common.locale.${locale}`)}:</Col>
                <Col sm={10}>
                  <Input
                    placeholder={t("components.newsForm.title")}
                    px="0px"
                    id={`title.${locale}`}
                    {...formik.getFieldProps(`title.${locale}`)}
                  />
                </Col>
              </Row>
            ))}
          </FormControl>

          <FormControl>
            <Label htmlFor="content">{t("components.newsForm.content")}</Label>
            {LOCALES.map((locale) => (
              <Row alignItems="center">
                <Col sm>{t(`common.locale.${locale}`)}:</Col>
                <Col sm={10}>
                  <textarea
                    id={`content.${locale}`}
                    {...formik.getFieldProps(`content.${locale}`)}
                    rows={3}
                  />
                </Col>
              </Row>
            ))}
          </FormControl>
        </StyledBody>
      </ScrollBar>
    </form>
  );
};

export default NewsForm;
