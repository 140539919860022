import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../components/Common/Content";
import PageHeader from "../../components/Orders/PageHeader";
import { useStores } from "../../hooks/useStores";
import dayjs from "dayjs";
import { Row } from "../../components/Common/Grid";
import { Company, DailySatisfaction } from "../../types";
import { calculateAvgFeedback } from "../../utils/orders";
import { SatisfactionMain } from "../../components/Satisfaction/Main/MainContent";

export const SatisfactionScreen: React.FC = observer(() => {
  const { t } = useTranslation();
  const { orders, users } = useStores();
  const [period, setPeriod] = useState([
    dayjs().day(0).hour(0).minute(0).second(0).millisecond(0),
    dayjs().day(6).hour(0).minute(0).second(0).millisecond(0),
  ]);
  const [activeCompanyId, setActiveCompanyId] = useState<Company["id"] | null>(
    null
  );

  useEffect(() => {
    orders.fetchOrders(period[0].toDate(), period[1].toDate());
  }, [orders, period]);

  useEffect(() => {
    if (!users.companies.length) {
      users.getCompanies();
    }
  }, [users]);

  const handleGoBack = useCallback(() => {
    const startDate = dayjs(period[0].day(-7));
    const newPeriod = [startDate, startDate.day(+6)];

    setPeriod(newPeriod);
  }, [period]);

  const handleGoNext = useCallback(() => {
    const startDate = dayjs(period[0].day(+7));
    const newPeriod = [startDate, startDate.day(+6)];

    setPeriod(newPeriod);
  }, [period]);

  const handleGoToday = useCallback(() => {
    const startDate = dayjs().day(0).hour(0).minute(0).second(0).millisecond(0);
    const newPeriod = [startDate, startDate.day(+6)];

    setPeriod(newPeriod);
  }, []);

  const activeOrders = useMemo(() => {
    return activeCompanyId
      ? orders.orders.filter(
          (order) => order.user.company?.id === activeCompanyId
        )
      : orders.orders;
  }, [activeCompanyId, orders.orders]);

  const periodOrders: DailySatisfaction[] = useMemo(() => {
    const list: DailySatisfaction[] = [];
    let currentDate = dayjs(period[0]);
    while (currentDate.isBefore(period[1]) || currentDate.isSame(period[1])) {
      const dailyOrders = activeOrders.filter((order) =>
        order.date.isSame(currentDate)
      );

      const {
        complessiveFeedbackAvg,
        complessiveFeedbackVotes,
        quantityFeedbackAvg,
        quantityFeedbackVotes,
        varietyFeedbackAvg,
        varietyFeedbackVotes,
      } = calculateAvgFeedback(dailyOrders);

      list.push({
        date: currentDate,
        orders: dailyOrders,
        complessiveFeedbackAvg,
        complessiveFeedbackVotes,
        quantityFeedbackAvg,
        quantityFeedbackVotes,
        varietyFeedbackAvg,
        varietyFeedbackVotes,
      });

      currentDate = dayjs(currentDate).add(1, "day");
    }

    return list;
  }, [period, activeOrders]);

  const handleChangeCompany = (companyId: any) => {
    if (companyId && String(companyId).length > 0) {
      setActiveCompanyId(Number(companyId));
    } else {
      setActiveCompanyId(null);
    }
  };

  return (
    <>
      <Content borderBottomWidth="1px">
        <PageHeader
          prev={[]}
          title={t("components.menu.satisfaction")}
          wcText={`${period[0].format("DD/MM")} - ${period[1].format(
            "DD/MM/YYYY"
          )}`}
          goBack={handleGoBack}
          goNext={handleGoNext}
          goToday={handleGoToday}
        />
      </Content>
      <Content mt={[null, null, null, "0px"]}>
        <Row>
          <SatisfactionMain
            periodOrders={periodOrders}
            companies={users.companies}
            onCompanyChange={handleChangeCompany}
            companyId={activeCompanyId}
          />
        </Row>
      </Content>
    </>
  );
});
