import { User } from "../../../types";
import ScrollBar from "../../Common/Scrollbar";
import SidebarHeader from "../../UI/Dishes/SidebarHeader";
import AllUsers from "../../UI/Users/AllUsers";

import { StyledSidebar, StyledContactList, StyledBody } from "./style";

type Props = {
  users: User[];
  activeId: number | undefined;
  clickHandler: (user: User) => void;
  disableSections?: boolean;
  onAddClick: () => void;
  searchedText: string;
  onSearchChange: (value: string) => any;
};

const Sidebar: React.FC<Props> = ({
  users,
  activeId,
  clickHandler,
  disableSections,
  onAddClick,
  searchedText,
  onSearchChange,
}: Props) => {
  return (
    <StyledSidebar $show={true}>
      <SidebarHeader
        searchedText={searchedText}
        setSearchedText={onSearchChange}
        onAddClick={onAddClick}
      />
      <StyledContactList>
        <ScrollBar>
          <StyledBody>
            <AllUsers
              users={users}
              clickHandler={clickHandler}
              activeId={activeId}
            />
          </StyledBody>
        </ScrollBar>
      </StyledContactList>
    </StyledSidebar>
  );
};

export default Sidebar;
