import styled, { themeGet } from "../../../styled";

export const StyledWrap = styled.div`
  width: 100%;
`;

export const StyledTitle = styled.h3`
  color: #142c43;
  margin-bottom: 5px;
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: #142c43;
  margin-bottom: 40px;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
  color: #142c43;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${themeGet("colors.text3")};
  font-size: 10px;
  font-weight: 500;
  font-family: ${themeGet("fonts.interui")};
  letter-spacing: 0.5px;
  margin: 15px 0;
  &:after,
  &:before {
    content: "";
    display: block;
    flex: 1;
    height: 1px;
    background-color: ${themeGet("colors.light")};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
`;
