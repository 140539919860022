import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import Group from "../../components/News/Group";
import Wrapper from "../../components/News/Wrapper";
import useDebounce from "../../hooks/useDebounce";
import { useStores } from "../../hooks/useStores";
import { fuzzyMatch } from "../../utils/string";

export const News: React.FC = observer(() => {
  const { news } = useStores();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const debouncedQuery = useDebounce(search, 300);
  const navigate = useNavigate();

  useEffect(() => {
    news.fetchNews();
  }, []);

  const filteredNews = useMemo(() => {
    return debouncedQuery.length > 0
      ? news.news.filter((news) =>
          fuzzyMatch(news.title[i18n.language], debouncedQuery)
        )
      : news.news;
  }, [debouncedQuery, news.news, i18n.language]);

  const handleAddClick = () => {
    navigate("./new");
  };

  return (
    <Wrapper>
      <Group
        news={filteredNews}
        search={search}
        onSearchChange={setSearch}
        onAddClick={handleAddClick}
      />
      <Outlet />
    </Wrapper>
  );
});
