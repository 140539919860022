import {
  Company,
  Dish,
  EditedFilter,
  ExportOrders,
  Filter,
  Order,
  OrderExportTypes,
  RoleName,
  Stores,
} from "../types";
import { makeAutoObservable } from "mobx";
import api from "../services/api";
import {
  aggregateCompanyDishes,
  aggregateDishes,
  aggregateMonthlyUsers,
  aggregateUsers,
} from "../utils/orders";
import i18n from "../i18n";

export class OrdersStore {
  stores: Stores;

  isFetching = false;

  orders: Order[] = [];
  filters: Filter[] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  fetchOrders = async (fromDate: Date, toDate: Date) => {
    this.isFetching = true;

    try {
      const orders = await api.fetchOrders(fromDate, toDate);

      if (this.stores.session.user?.role?.name === RoleName.OrganizationAdmin) {
        this.orders = orders.filter(
          (order) =>
            order.user.company?.id === this.stores.session.user?.company?.id
        );
      } else {
        this.orders = orders;
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  fetchExportOrders = async (data: ExportOrders): Promise<Order[]> => {
    let fromDate = new Date(data.fromDate);
    let toDate = new Date(data.toDate);

    if (Number(data.type) === Number(OrderExportTypes.UsersMonthlyAggregate)) {
      fromDate.setDate(1);
      // toDate should be the last day of the month
      toDate.setMonth(toDate.getMonth() + 1);
      toDate.setDate(0);
    }

    let orders = await api.fetchOrders(
      fromDate,
      toDate,
      data.company_ids.length > 0 ? data.company_ids : undefined
    );

    if (data.partition) {
      orders = orders.map((order) => {
        var dishes = order.dishes.filter((dish) => {
          return (
            (data.partition === "hotColdPlates" &&
              ["hotPlate", "coldPlate"].includes(String(dish.partitionType))) ||
            (data.partition === "hotPlateMagazine" &&
              ["hotPlate", "magazine"].includes(String(dish.partitionType))) ||
            (data.partition === "coldPlateMagazine" &&
              ["coldPlate", "magazine"].includes(String(dish.partitionType))) ||
            dish.partitionType === data.partition
          );
        });

        order.dishes = dishes;

        return order;
      });
    }

    if (data.workShift && data.workShift.length > 0) {
      orders = orders.filter((order) => {
        return order.workshift && order.workshift !== "false"
          ? order.workshift === data.workShift
          : order.user.workshift === data.workShift;
      });
    }

    let filterByCompanyId = Number(data.company_ids);

    if (this.stores.session.user?.role?.name === RoleName.OrganizationAdmin) {
      filterByCompanyId = Number(this.stores.session.user.company?.id);
    }

    return filterByCompanyId > 0
      ? orders.filter((order) => order.user.company?.id === filterByCompanyId)
      : orders;
  };

  exportOrders = async (data: ExportOrders): Promise<Blob> => {
    let blob: Blob;
    let csvData: any[][] = [];

    try {
      let orders = await this.fetchExportOrders(data);

      if (data.partition) {
        orders = orders.map((order) => {
          var dishes = order.dishes.filter((dish) => {
            return (
              (data.partition === "hotColdPlates" &&
                ["hotPlate", "coldPlate"].includes(
                  String(dish.partitionType)
                )) ||
              (data.partition === "hotPlateMagazine" &&
                ["hotPlate", "magazine"].includes(
                  String(dish.partitionType)
                )) ||
              (data.partition === "coldPlateMagazine" &&
                ["coldPlate", "magazine"].includes(
                  String(dish.partitionType)
                )) ||
              dish.partitionType === data.partition
            );
          });
          order.dishes = dishes;
          return order;
        });
      }

      if (data.workShift) {
        orders = orders.filter(
          (order) => order.user.workshift === data.workShift
        );
      }

      if (
        this.stores.session.user?.role?.name === RoleName.OrganizationAdmin &&
        this.stores.session.user.company?.id
      ) {
        orders = orders.filter(
          (order) =>
            order.user.company?.id ===
            Number(this.stores.session.user?.company?.id)
        );
      }

      if (Number(data.type) === Number(OrderExportTypes.DishesAggregate)) {
        const aggrDishes = aggregateDishes(orders);

        csvData = [
          [
            i18n.t("components.orders.export.day").toUpperCase(),
            i18n.t("components.orders.export.dish").toUpperCase(),
            i18n.t("components.orders.export.count").toUpperCase(),
            i18n.t("components.orders.export.partition").toUpperCase(),
          ],
        ];

        const rows = [];

        for (let order of aggrDishes) {
          // const PLATE_PARTITION = ["hotPlate", "coldPlate", "magazine"];

          rows.push([
            order.date.format("DD/MM/YYYY"),
            order.dish.name[i18n.language],
            String(order.count),
            order.dish.partitionType
              ? i18n.t(
                  `components.dishForm.partitionType.${order.dish.partitionType}`
                )
              : "",
          ]);
        }
        // sort row by company name
        rows.sort((a, b) => a[1].localeCompare(b[1]));

        for (let row of rows) {
          csvData.push(row);
        }
      } else if (
        Number(data.type) === Number(OrderExportTypes.DishesCompaniesAggregate)
      ) {
        const aggrDishes = aggregateCompanyDishes(orders);

        csvData = [
          [
            i18n.t("components.orders.export.day").toUpperCase(),
            i18n.t("components.orders.export.company").toUpperCase(),
            i18n.t("components.orders.export.dish").toUpperCase(),
            i18n.t("components.orders.export.partition").toUpperCase(),
            i18n.t("components.orders.export.count").toUpperCase(),
          ],
        ];
        const rows = [];

        for (let order of aggrDishes) {
          rows.push([
            order.date.format("DD/MM/YYYY"),
            order.company?.name || "",
            order.dish.name[i18n.language],
            order.dish.partitionType
              ? i18n.t(
                  `components.dishForm.partitionType.${order.dish.partitionType}`
                )
              : "",
            String(order.count),
          ]);
        }

        // sort row by company name
        rows.sort(
          (a, b) => a[1].localeCompare(b[1]) || a[3].localeCompare(b[3])
        );

        for (let row of rows) {
          csvData.push(row);
        }
      } else if (Number(data.type) === Number(OrderExportTypes.UserDetail)) {
        csvData = [
          [
            i18n.t("components.orders.export.day").toUpperCase(),
            i18n.t("components.orders.export.company").toUpperCase(),
            i18n.t("components.orders.export.user").toUpperCase(),
            i18n.t("components.orders.export.referenceOffice").toUpperCase(),
            i18n.t("components.orders.export.workshift").toUpperCase(),
            i18n.t("components.orders.export.dish").toUpperCase(),
            i18n.t("components.orders.export.partition").toUpperCase(),
            i18n.t("components.orders.export.count").toUpperCase(),
          ],
        ];

        const rows = [];

        for (let order of orders) {
          if (
            (data.workShift &&
              data.workShift.length > 0 &&
              order.user.workshift === data.workShift) ||
            !Boolean(data.workShift) ||
            (data.workShift && data.workShift.length === 0)
          ) {
            for (let dish of order.dishes) {
              rows.push([
                order.date.format("DD/MM/YYYY"),
                order.user.company?.name || "",
                order.user.username,
                order.user.referenceOffice || "",
                order.user.workshift && order.user.workshift.length > 0
                  ? i18n.t(`common.workshifts.${order.user.workshift}`)
                  : "",
                dish.name[i18n.language],
                dish.partitionType
                  ? i18n.t(
                      `components.dishForm.partitionType.${dish.partitionType}`
                    )
                  : "",
                String(dish.platesNumber),
              ]);
            }
          }
        }
        // sort row by company name
        rows.sort(
          (a, b) => a[1].localeCompare(b[1]) || a[6].localeCompare(b[6])
        );

        for (let row of rows) {
          csvData.push(row);
        }
      } else if (
        Number(data.type) === Number(OrderExportTypes.UsersAggregate)
      ) {
        const aggrUsers = aggregateUsers(orders).sort(
          (a, b) =>
            a.user.username.localeCompare(b.user.username) ||
            a.date.diff(b.date)
        );
        csvData = [
          [
            i18n.t("components.orders.export.user").toUpperCase(),
            i18n.t("components.orders.export.workshift").toUpperCase(),
            i18n.t("components.orders.export.company").toUpperCase(),
            i18n.t("components.orders.export.day").toUpperCase(),
            i18n.t("components.orders.export.count").toUpperCase(),
          ],
        ];

        const rows = [];

        for (let item of aggrUsers) {
          if (
            (data.workShift &&
              data.workShift.length > 0 &&
              item.user.workshift === data.workShift) ||
            !Boolean(data.workShift) ||
            (data.workShift && data.workShift.length === 0)
          ) {
            rows.push([
              item.user.username,
              item.user.workshift && item.user.workshift.length > 0
                ? i18n.t(`common.workshifts.${item.user.workshift}`)
                : "",
              item.user.company?.name || "",
              item.date.format("DD/MM/YYYY"),

              String(item.count),
            ]);
          }
        }

        // sort row by company name
        rows.sort((a, b) => a[2].localeCompare(b[2]));

        for (let row of rows) {
          csvData.push(row);
        }
      } else if (
        Number(data.type) === Number(OrderExportTypes.UsersMonthlyAggregate)
      ) {
        // Keep only orders for the month of the fromDate
        orders = orders.filter((order) =>
          order.date.isSame(data.fromDate, "month")
        );
        const aggrUsers = aggregateMonthlyUsers(orders).sort(
          (a, b) =>
            a.user.username.localeCompare(b.user.username) ||
            a.date.diff(b.date)
        );
        csvData = [
          [
            i18n.t("components.orders.export.user").toUpperCase(),
            i18n.t("components.orders.export.workshift").toUpperCase(),
            i18n.t("components.orders.export.company").toUpperCase(),
            i18n.t("components.orders.export.day").toUpperCase(),
            i18n.t("components.orders.export.count").toUpperCase(),
          ],
        ];

        const rows = [];

        for (let item of aggrUsers) {
          if (
            (data.workShift &&
              data.workShift.length > 0 &&
              item.user.workshift === data.workShift) ||
            !Boolean(data.workShift) ||
            (data.workShift && data.workShift.length === 0)
          ) {
            rows.push([
              item.user.username,
              item.user.workshift && item.user.workshift.length > 0
                ? i18n.t(`common.workshifts.${item.user.workshift}`)
                : "",
              item.user.company?.name || "",
              item.date.format("MMMM YYYY"),
              String(item.count),
            ]);
          }
        }

        // sort row by company name
        rows.sort((a, b) => a[2].localeCompare(b[2]));

        for (let row of rows) {
          csvData.push(row);
        }
      }

      blob = await api.exportOrders(csvData);

      return blob;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  getFilters = async (userId: number) => {
    this.isFetching = true;

    try {
      const filters = await api.getFilters(userId);

      this.filters = filters;
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  createFilter = async (data: EditedFilter) => {
    this.isFetching = true;

    try {
      const filter = await api.createFilter(data);
      this.filters = [...this.filters, filter];
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  updateFilter = async (data: EditedFilter) => {
    this.isFetching = true;
    try {
      const filter = await api.updateFilter(data);
      this.filters = this.filters.map((oldFilter) =>
        oldFilter.id === filter?.id ? filter : oldFilter
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  deleteFilter = async (data: EditedFilter) => {
    this.isFetching = true;

    try {
      await api.deleteFilter(data.id);
      this.filters = this.filters.filter((filter) => filter.id !== data.id);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };
}
