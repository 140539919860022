import { EditedPNMessage, PNMessage, Stores } from "../types";
import { makeAutoObservable } from "mobx";
import api from "../services/api";

export class PushNotificationsStore {
  stores: Stores;

  isFetching = false;
  isSubmitting = false;

  pNMessages: PNMessage[] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  fetchPushNotifications = async () => {
    this.isFetching = true;

    try {
      this.pNMessages = await api.fetchPushNotifications();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  addPNMessage = async (data: EditedPNMessage) => {
    this.isSubmitting = true;
    let newMessage;
    try {
      newMessage = await api.addPNMessage(data);
      this.pNMessages.push(newMessage);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return newMessage;
  };
}
