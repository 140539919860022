import { Col, Row } from "../../Common/Grid";
import { Company, DailySatisfaction } from "../../../types";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Select } from "../../Common/Forms";
import Group from "../../Common/Group";
import Text from "../../Common/Text";
import { StyledHeader } from "./style";
import { Table } from "../../Common/Table";
import { StyledTheadTR } from "../../Dishes/DishForm/style";
import { calculateTotalAvgFeedback } from "../../../utils/orders";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import Chart from "react-apexcharts";
import { useMemo } from "react";

const VOTES_COLORS = {
  0: "#cc0000",
  1: "#ff9900",
  2: "#33cc33",
};

type Props = {
  periodOrders: DailySatisfaction[];
  companies: Company[];
  companyId: Company["id"] | null;
  onCompanyChange: (companyId: Company["id"] | null) => void;
};

export const SatisfactionMain: React.FC<Props> = ({
  periodOrders,
  companies,
  companyId,
  onCompanyChange,
}) => {
  const { i18n } = useTranslation();

  const feedbackAvg = useMemo(() => {
    const data = periodOrders.map((dailyOrders) => {
      return dailyOrders.complessiveFeedbackAvg !== undefined
        ? dailyOrders.complessiveFeedbackAvg
        : null;
    });
    return data;
  }, [periodOrders]);

  const varietyFeedbackAvg = useMemo(() => {
    const data = periodOrders.map((dailyOrders) => {
      return dailyOrders.varietyFeedbackAvg !== undefined
        ? dailyOrders.varietyFeedbackAvg
        : null;
    });
    return data;
  }, [periodOrders]);

  const quantityFeedbackAvg = useMemo(() => {
    const data = periodOrders.map((dailyOrders) => {
      return dailyOrders.quantityFeedbackAvg !== undefined
        ? dailyOrders.quantityFeedbackAvg
        : null;
    });
    return data;
  }, [periodOrders]);

  const totalAvg = useMemo(() => {
    const data = periodOrders.map((dailyOrders) => {
      const total = calculateTotalAvgFeedback(
        dailyOrders.complessiveFeedbackAvg,
        dailyOrders.varietyFeedbackAvg,
        dailyOrders.quantityFeedbackAvg
      );
      return total !== "-" ? total : null;
    });
    return data;
  }, [periodOrders]);

  const emptyData = useMemo(() => {
    const feedbackEmpty = feedbackAvg.reduce(
      (acc, curr) => (acc === null ? (curr === null ? null : curr) : acc),
      null
    );
    const varietyFeedbackEmpty = varietyFeedbackAvg.reduce(
      (acc, curr) => (acc === null ? (curr === null ? null : curr) : acc),
      null
    );
    const quantityFeedbackEmpty = quantityFeedbackAvg.reduce(
      (acc, curr) => (acc === null ? (curr === null ? null : curr) : acc),
      null
    );
    return feedbackEmpty && varietyFeedbackEmpty && quantityFeedbackEmpty;
  }, [feedbackAvg, varietyFeedbackAvg, quantityFeedbackAvg]);

  const chartData = {
    series:
      emptyData === null
        ? []
        : [
            {
              name: i18n.t("components.satisfaction.complessiveFeedbackAvg"),
              type: "line",
              data: feedbackAvg,
            },
            {
              name: i18n.t("components.satisfaction.varietyFeedbackAvg"),
              type: "line",
              data: varietyFeedbackAvg,
            },
            {
              name: i18n.t("components.satisfaction.quantityFeedbackAvg"),
              type: "line",
              data: quantityFeedbackAvg,
            },
            {
              name: i18n.t("components.satisfaction.total"),
              type: "line",
              data: totalAvg,
            },
          ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: i18n.t("components.satisfaction.byDay"),
        align: "left",
      },
      yaxis: { min: 0, max: 5 },
      xaxis: {
        categories: periodOrders.map((dailyOrders) => {
          return dailyOrders.date.format("DD/MM");
        }),
      },
      noData: {
        text: i18n.t("components.satisfaction.noData"),
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -25,
        style: {
          color: "#000000",
          fontSize: "20px",
          fontFamily: "Helvetica",
          fontWeight: "bold",
        },
      },
    },
  };

  return (
    <Col lg={12} margin="0 auto">
      <Row alignItems="center" mb={"50px"}>
        <Col sm={9} />
        <Col sm={3}>
          <Select
            value={companyId}
            onChange={(event: React.ChangeEvent) =>
              // @ts-ignore
              onCompanyChange(event.target.value)
            }
          >
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row mb={"50px"}>
        <Col sm={2} />
        <Col sm={8}>
          <Chart
            options={chartData.options as any}
            series={chartData.series as any}
            type="line"
          />
        </Col>
        <Col sm={2} />
      </Row>
      <Table marginTop={"25px"}>
        <thead>
          <StyledTheadTR>
            {[
              i18n.t("components.satisfaction.feedback").toLocaleUpperCase(),
              i18n
                .t("components.satisfaction.complessiveFeedbackAvg")
                .toLocaleUpperCase(),
              i18n
                .t("components.satisfaction.varietyFeedbackAvg")
                .toLocaleUpperCase(),
              i18n
                .t("components.satisfaction.quantityFeedbackAvg")
                .toLocaleUpperCase(),
              `${i18n
                .t("components.satisfaction.total")
                .toLocaleUpperCase()} (${i18n
                .t("components.satisfaction.votesAmount")
                .toLocaleUpperCase()})`,
            ].map((text) => {
              return (
                <th style={{ textAlign: "center" }}>
                  <Text as="h6" fontWeight={"bold"}>
                    {text}
                  </Text>
                </th>
              );
            })}
          </StyledTheadTR>
        </thead>
        <tbody>
          {periodOrders.map((dailyOrders) => {
            const totalAvgFeedback = calculateTotalAvgFeedback(
              dailyOrders.complessiveFeedbackAvg,
              dailyOrders.varietyFeedbackAvg,
              dailyOrders.quantityFeedbackAvg
            );
            return (
              <tr>
                <td>
                  <Group>
                    <StyledHeader>
                      <Text as="h2">{dailyOrders.date.format("DD/MM")}</Text>
                      <Text as="h3">
                        {t(
                          `components.calendar.daysOfWeek.${dailyOrders.date.day()}`
                        )}
                      </Text>
                    </StyledHeader>
                  </Group>
                </td>
                {[
                  dailyOrders.complessiveFeedbackAvg,
                  dailyOrders.varietyFeedbackAvg,
                  dailyOrders.quantityFeedbackAvg,
                  totalAvgFeedback !== "-"
                    ? `${totalAvgFeedback} (${dailyOrders.complessiveFeedbackVotes})`
                    : totalAvgFeedback,
                ].map((text) => {
                  return (
                    <td style={{ textAlign: "center" }}>
                      <Text
                        as={"h4"}
                        fontWeight={"bolder"}
                        color={
                          text && text !== "-"
                            ? parseInt(text.toString()) > 0 &&
                              parseInt(text.toString()) < 3
                              ? VOTES_COLORS[0]
                              : parseInt(text.toString()) < 4
                              ? VOTES_COLORS[1]
                              : VOTES_COLORS[2]
                            : "black"
                        }
                      >
                        {text || "-"}
                      </Text>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row marginBottom={"10em"}>
        <Col>
          <Row justifyContent={"center"}>
            <Avatar size={"sm"}>
              <AvatarInitial backgroundColor={VOTES_COLORS[0]}>
                0-3
              </AvatarInitial>
            </Avatar>
            <Avatar size={"sm"}>
              <AvatarInitial backgroundColor={VOTES_COLORS[1]}>
                3-4
              </AvatarInitial>
            </Avatar>
            <Avatar size={"sm"}>
              <AvatarInitial backgroundColor={VOTES_COLORS[2]}>
                4-5
              </AvatarInitial>
            </Avatar>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
