import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import Wrapper from "../../components/Users/Wrapper";
import Sidebar from "../../components/Users/Sidebar";
import { useStores } from "../../hooks/useStores";
import { User } from "../../types";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import { fuzzyMatch } from "../../utils/string";

export const Users: React.FC = observer(() => {
  const { users } = useStores();
  const params = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const debouncedQuery = useDebounce(search, 300);

  useEffect(() => {
    users.getUsers();
    users.getCompanies();
    users.getRoles();
    users.getDepartments();
  }, [users]);

  const activeUserId = useMemo(() => {
    return params.userId ? Number(params.userId) : undefined;
  }, [params]);

  const handleUserClick = useCallback(
    (user: User) => {
      navigate(`./${user.id}`);
    },
    [navigate]
  );

  const handleAddClick = () => {
    navigate("./new");
  };

  const filteredUsers = useMemo(() => {
    return debouncedQuery.length > 0
      ? users.users.filter((user) =>
          fuzzyMatch(user.email + user.username, debouncedQuery)
        )
      : users.users;
  }, [debouncedQuery, users.users]);

  return (
    <Wrapper>
      <Sidebar
        onAddClick={handleAddClick}
        users={filteredUsers}
        activeId={activeUserId}
        clickHandler={handleUserClick}
        searchedText={search}
        onSearchChange={setSearch}
      />
      <Outlet />
    </Wrapper>
  );
});
