import logo from "../../assets/images/SK_logo_extended.png";
import "../../App.css";

export const AppLoader: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Service Key - Facility Management</p>
      </header>
    </div>
  );
};
