import {
  Company,
  Department,
  EditedUSer,
  Role,
  RoleName,
  Stores,
  User,
} from "../types";
import { makeAutoObservable } from "mobx";
import api from "../services/api";

export class UsersStore {
  stores: Stores;
  isSubmitting = false;
  users: User[] = [];
  companies: Company[] = [];
  roles: Role[] = [];
  departments: Department[] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  getUsers = async (orderByName?: boolean): Promise<User[]> => {
    let toReturn: User[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.getUsers();
      if (orderByName) {
        toReturn.sort((a, b) => {
          let fa = a.username.toLowerCase(),
            fb = b.username.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }

    if (this.stores.session.user?.role?.name === RoleName.OrganizationAdmin) {
      this.users = toReturn.filter(
        (record) => record.company?.id === this.stores.session.user?.company?.id
      );
    } else {
      this.users = toReturn;
    }

    return toReturn;
  };

  getCompanies = async (orderByName?: boolean): Promise<Company[]> => {
    let toReturn: Company[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.fetchCompanies();
      if (orderByName) {
        toReturn.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }

    if (this.stores.session.user?.role?.name === RoleName.OrganizationAdmin) {
      this.companies = toReturn.filter(
        (record) => record.id === this.stores.session.user?.company?.id
      );
    } else {
      this.companies = toReturn;
    }

    return toReturn;
  };

  getRoles = async (orderByName?: boolean): Promise<Role[]> => {
    let toReturn: Role[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.fetchRoles();

      if (orderByName) {
        toReturn.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }

    if (this.stores.session.user?.role?.name === RoleName.OrganizationAdmin) {
      this.roles = toReturn.filter(
        (record) => record.name !== RoleName.Administrator
      );
    } else {
      this.roles = toReturn;
    }

    return toReturn;
  };

  getDepartments = async (orderByName?: boolean): Promise<Department[]> => {
    let toReturn: Department[] = [];
    this.isSubmitting = true;
    try {
      toReturn = await api.fetchDepartments();

      if (orderByName) {
        toReturn.sort((a, b) => {
          let fa = a.department.toLowerCase(),
            fb = b.department.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    this.departments = toReturn;
    return toReturn;
  };

  createUser = async (user: EditedUSer): Promise<User> => {
    this.isSubmitting = true;
    let toReturn: User;
    try {
      toReturn = await api.createUser(user);
      this.users.push(toReturn);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  updateUser = async (user: EditedUSer): Promise<User | null> => {
    this.isSubmitting = true;
    let toReturn: User | null = null;
    try {
      if (user.id) {
        // @ts-ignore
        toReturn = await api.updateUser(user);
      }
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      this.isSubmitting = false;
    }
    return toReturn;
  };

  deleteUser = async (id: User["id"]) => {
    this.isSubmitting = true;
    try {
      await api.deleteUser(id);
      this.users = this.users.filter((user) => user.id !== id);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      this.isSubmitting = false;
    }
  };
}
