import { Col, Row } from "../../Common/Grid";
import { Company, DailyOrders } from "../../../types";
import { StyledMedia } from "./style";
import { MediaBody } from "../../UI/Media";
import { StyledName } from "../../UI/Dishes/DishItem/style";
import { useTranslation } from "react-i18next";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import Group from "../../Common/Group";
import { StyledHeader } from "./style";
import Text from "../../Common/Text";
import { t } from "i18next";
import { Select } from "../../Common/Forms";
import workshifts from "../../../config/workshifts";

type Props = {
  periodOrders: DailyOrders[];
  companies: Company[];
  companyId: Company["id"] | null;
  workshift: string;
  onCompanyChange: (companyId: Company["id"] | null) => void;
  onWorkshiftChange: (workshift: string) => void;
};

const CATEGORY_COLOR_BY_ID = {
  1: "#DB3DDB",
  2: "#F2AA35",
  3: "#74C4F2",
  4: "#10b759",
};

export const MainContent: React.FC<Props> = ({
  periodOrders,
  companies,
  companyId,
  workshift,
  onCompanyChange,
  onWorkshiftChange,
}) => {
  const { i18n } = useTranslation();

  return (
    <Col lg={12} margin="0 auto">
      <Row alignItems="center">
        <Col sm />
        <Col sm={4}>
          <Row>
            <Col sm>{t("components.exportModal.company")}:</Col>
            <Col sm={9}>
              <Select
                value={companyId}
                onChange={(event: React.ChangeEvent) =>
                  // @ts-ignore
                  onCompanyChange(event.target.value)
                }
              >
                <option value="">
                  {t(`components.exportModal.allCompanies`)}
                </option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <Row>
            <Col sm>{t("components.exportModal.workshift")}:</Col>
            <Col sm={9}>
              <Select
                value={workshift}
                onChange={(event: React.ChangeEvent) =>
                  // @ts-ignore
                  onWorkshiftChange(event.target.value)
                }
              >
                <option value="">
                  {t(`components.exportModal.allWorkshifts`)}
                </option>
                {workshifts.map((w) => (
                  <option key={w} value={w}>
                    {t(`common.workshifts.${w}`)}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutters={0}>
        {periodOrders.map((dailyOrders) => (
          <Col sm={1.7} mt="10px" paddingLeft="4px" paddingRight="4px">
            <Group>
              <StyledHeader>
                <Text as="h2">{dailyOrders.date.format("DD/MM")}</Text>
                <Text as="h3">
                  {t(
                    `components.calendar.daysOfWeek.${dailyOrders.date.day()}`
                  )}
                </Text>
              </StyledHeader>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {dailyOrders.aggrDishes.map((aggrDish) => (
                  <StyledMedia>
                    <Avatar size="sm">
                      <AvatarInitial
                        backgroundColor={
                          // @ts-ignore
                          CATEGORY_COLOR_BY_ID[
                            aggrDish.dish.plateCategory?.id || 1
                          ]
                        }
                      >
                        {aggrDish.count}
                      </AvatarInitial>
                    </Avatar>
                    <MediaBody ml="10px">
                      <StyledName>
                        {aggrDish.dish.name[i18n.language]}
                      </StyledName>
                    </MediaBody>
                  </StyledMedia>
                ))}
              </div>
            </Group>
          </Col>
        ))}
      </Row>
    </Col>
  );
};
