import { FC } from "react";
import { Search } from "react-feather";
import { StyledGroup, StyledHeader, StyledBody, StyledList } from "./style";
import ScrollBar from "../../Common/Scrollbar";
import SearchForm from "../SearchForm";
import GroupItem from "../GroupItem";
import { useNavigate } from "react-router-dom";
import { NotificationsEmptyState } from "../EmptyState";
import { PNMessage } from "../../../types";
import { Button } from "../../Common/Button";
import { HiOutlinePlus } from "react-icons/hi";

type Props = {
  messages: PNMessage[];
  search: string;
  onSearchChange: (value: string) => void;
  onAddClick: () => void;
};

const Group: FC<Props> = ({ messages, search, onSearchChange, onAddClick }) => {
  const navigate = useNavigate();

  const handleClick = (id: any) => {
    navigate(`./${id}`);
  };

  return (
    <StyledGroup>
      <StyledHeader>
        <Search className="search" />
        <SearchForm value={search} onChange={onSearchChange} />
        <Button size={"md"} iconButton onClick={onAddClick}>
          <HiOutlinePlus />
        </Button>
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <StyledList>
            {messages.length === 0 && <NotificationsEmptyState />}
            {messages.map((message) => (
              <GroupItem
                key={message.id}
                color={"red"}
                time={message.createdAt.format("DD/MM/YYYY")}
                title={message.title}
                excerpt=""
                name=""
                onClick={() => handleClick(message.id)}
              />
            ))}
          </StyledList>
        </StyledBody>
      </ScrollBar>
    </StyledGroup>
  );
};

export default Group;
