import { useMemo } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/PNMessages/Main";
import { useStores } from "../../hooks/useStores";

export const PNMessageDetail: React.FC = () => {
  const { pushNotifications } = useStores();
  const params = useParams();

  const activeMessageId = useMemo(() => {
    return Number(params.messageId);
  }, [params]);

  const activeMessage = useMemo(() => {
    return activeMessageId
      ? pushNotifications.pNMessages.find((item) => item.id === activeMessageId)
      : null;
  }, [activeMessageId, pushNotifications.pNMessages]);

  return activeMessage ? (
    <Main message={activeMessage} isLoading={pushNotifications.isFetching} />
  ) : null;
};
