import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Group from "../../components/PNMessages/Group";
import Wrapper from "../../components/PNMessages/Wrapper";
import useDebounce from "../../hooks/useDebounce";
import { useStores } from "../../hooks/useStores";
import { fuzzyMatch } from "../../utils/string";

export const PNMessages: React.FC = observer(() => {
  const { pushNotifications } = useStores();
  const [search, setSearch] = useState("");
  const debouncedQuery = useDebounce(search, 300);
  const navigate = useNavigate();

  useEffect(() => {
    pushNotifications.fetchPushNotifications();
  }, []);

  const filteredMessages = useMemo(() => {
    return debouncedQuery.length > 0
      ? pushNotifications.pNMessages.filter((message) =>
          fuzzyMatch(message.title, debouncedQuery)
        )
      : pushNotifications.pNMessages;
  }, [debouncedQuery, pushNotifications.pNMessages]);

  const handleAddClick = () => {
    navigate("./new");
  };

  return (
    <Wrapper>
      <Group
        messages={filteredMessages}
        search={search}
        onSearchChange={setSearch}
        onAddClick={handleAddClick}
      />
      <Outlet />
    </Wrapper>
  );
});
