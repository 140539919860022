import { FC } from "react";
import { ThemeProvider, themes } from "./styled";
import { GlobalStyle } from "./components/UI/Css";

const Theme: FC = ({ children }) => {
  return (
    <ThemeProvider theme={themes.classic}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
