import { observer } from "mobx-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../components/Users/Main";
import { StyledBody } from "../../components/Users/Main/style";
import { useStores } from "../../hooks/useStores";
import { EditedUSer } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import UserForm from "../../components/Users/UserForm";

export const NewUserScreen: React.FC = observer(() => {
  const { users } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: EditedUSer) => {
      try {
        const newUser = await users.createUser(data);
        toast.success(t("messages.successes.createUserForm.generic"));
        users.getUsers(true);
        navigate(`../${newUser.id}`);
      } catch (e: any) {
        toast.error(t("messages.errors.userForm.generic"));
        console.log(e);
      }
    },
    [users, navigate, t]
  );

  return (
    <Main>
      <StyledBody>
        <UserForm
          isSubmitting={users.isSubmitting}
          onSubmit={handleSubmit}
          roles={users.roles}
          companies={users.companies}
          departments={users.departments}
        />
      </StyledBody>
    </Main>
  );
});
