import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { StyledWrap, StyledTitle, StyledDesc, StyledLabelWrap } from "./style";
import { hasKey } from "../../../utils/methods";
import { FormGroup, Input, Label } from "../../Common/Forms";
import { Anchor } from "../../Common/Anchor";
import { Button } from "../../Common/Button";
import { useTranslation } from "react-i18next";
import { Alert } from "../../Common/Alert";
import { AlertMessage } from "../../../types";

interface IFormValues {
  username: string;
  password: string;
}

type Props = {
  message?: AlertMessage;
  isSubmitting: boolean;
  onSubmit: (username: string, password: string) => void;
};

const SigninForm: FC<Props> = ({ message, isSubmitting, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (data: IFormValues) => {
      onSubmit(data.username, data.password);
    },
    [onSubmit]
  );

  return (
    <StyledWrap>
      <StyledTitle>{t("components.signinForm.title")}</StyledTitle>
      <StyledDesc>{t("components.signinForm.description")}</StyledDesc>
      {message && (
        <Alert
          hasIcon={false}
          hasLink={false}
          isDismissible={false}
          solid={false}
          variant="contained"
          color={message.type}
        >
          {message.message}
        </Alert>
      )}
      {/* @ts-ignore */}
      <form action="#" onSubmit={handleSubmit(handleOnSubmit)} noValidate>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="username">
            {t("components.signinForm.email")}
          </Label>
          <Input
            type="text"
            id="username"
            placeholder="nome@email.com"
            feedbackText={errors?.username?.message}
            state={hasKey(errors, "username") ? "error" : "success"}
            showState={!!hasKey(errors, "username")}
            {...register("username", {
              required: t(
                "messages.formValidations.common.emailRequired"
              ).toString(),
            })}
          />
        </FormGroup>
        <FormGroup mb="20px">
          <StyledLabelWrap>
            <Label display="block" mb="5px" htmlFor="password">
              {t("common.password")}
            </Label>
          </StyledLabelWrap>
          <Input
            id="password"
            type="password"
            placeholder="Inserisci la password"
            feedbackText={errors?.password?.message}
            state={hasKey(errors, "password") ? "error" : "success"}
            showState={!!hasKey(errors, "password")}
            {...register("password", {
              required: t(
                "messages.formValidations.common.passwordRequired"
              ).toString(),
              minLength: {
                value: 6,
                message: t(
                  "messages.formValidations.common.passwordMinLength"
                ).toString(),
              },
            })}
          />
        </FormGroup>
        <Button type="submit" fullwidth disabled={isSubmitting}>
          {t("components.signinForm.signin")}
        </Button>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "2rem",
          }}
        >
          <Anchor path="/forgot-password" fontSize="16px" color="#142C43">
            {t("components.signinForm.forgotPassword")}
          </Anchor>
        </div>
      </form>
    </StyledWrap>
  );
};

export default SigninForm;
