import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../components/Common/Content";
import PageHeader from "../../components/Orders/PageHeader";
import { useStores } from "../../hooks/useStores";
import dayjs from "dayjs";
import { Row } from "../../components/Common/Grid";
import { MainContent } from "../../components/Orders/Main";
import {
  Company,
  DailyOrders,
  EditedFilter,
  ExportOrders,
  OrderExportTypes,
} from "../../types";
import { ExportModal } from "../../components/Orders/ExportModal";
import { useDisclosure } from "../../hooks/useDisclosure";
import { aggregateDishes } from "../../utils/orders";
import ReactPDF from "@react-pdf/renderer";
import { OrderStickyLabelsPDF } from "../../components/Orders/OrderStickyLabelsPDF";
import { toast } from "react-toastify";
import { WeeklyMenuPDF } from "../../components/Orders/WeeklyMenuPDF";

export const OrdersScreen: React.FC = observer(() => {
  const { t } = useTranslation();
  const { orders, users, dishes, session } = useStores();
  const [period, setPeriod] = useState([
    dayjs().day(0).hour(0).minute(0).second(0).millisecond(0),
    dayjs().day(6).hour(0).minute(0).second(0).millisecond(0),
  ]);
  const { close, isOpen, open } = useDisclosure();
  const [activeCompanyId, setActiveCompanyId] = useState<Company["id"] | null>(
    null
  );
  const [activeWorkshift, setActiveWorkshift] = useState("");

  const getFilters = useCallback(() => {
    if (session.user?.id && orders.filters) {
      orders.getFilters(session.user.id);
    }
  }, [orders, session.user?.id]);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    orders.fetchOrders(period[0].toDate(), period[1].toDate());
    if (!dishes.dishes.length) {
      dishes.fetchDishes();
    }
  }, [dishes, orders, period]);

  useEffect(() => {
    if (!users.companies.length) {
      users.getCompanies();
    }
  }, [users]);

  const handleGoBack = useCallback(() => {
    const startDate = dayjs(period[0].day(-7));
    const newPeriod = [startDate, startDate.day(+6)];

    setPeriod(newPeriod);
  }, [period]);

  const handleGoNext = useCallback(() => {
    const startDate = dayjs(period[0].day(+7));
    const newPeriod = [startDate, startDate.day(+6)];

    setPeriod(newPeriod);
  }, [period]);

  const handleGoToday = useCallback(() => {
    const startDate = dayjs().day(0).hour(0).minute(0).second(0).millisecond(0);
    const newPeriod = [startDate, startDate.day(+6)];

    setPeriod(newPeriod);
  }, []);

  const activeOrders = useMemo(() => {
    let list = orders.orders;

    if (activeCompanyId) {
      list = orders.orders.filter(
        (order) => order.user.company?.id === activeCompanyId
      );
    }

    if (activeWorkshift && activeWorkshift.length > 0) {
      list = list.filter((item) => item.user.workshift === activeWorkshift);
    }

    return list;
  }, [activeCompanyId, activeWorkshift, orders.orders]);

  const periodOrders: DailyOrders[] = useMemo(() => {
    const list: DailyOrders[] = [];
    let currentDate = dayjs(period[0]);
    while (currentDate.isBefore(period[1]) || currentDate.isSame(period[1])) {
      const dailyOrders = activeOrders.filter((order) =>
        order.date.isSame(currentDate)
      );

      let aggrDishes = aggregateDishes(dailyOrders);

      list.push({ date: currentDate, orders: dailyOrders, aggrDishes });

      currentDate = dayjs(currentDate).add(1, "day");
    }

    return list;
  }, [period, activeOrders]);

  const handleExportOrders = useCallback(
    async (data: ExportOrders) => {
      try {
        let blob: Blob | null = null;
        if (
          [
            OrderExportTypes.DishesCompaniesAggregate,
            OrderExportTypes.DishesAggregate,
            OrderExportTypes.UserDetail,
            OrderExportTypes.UsersAggregate,
            OrderExportTypes.UsersMonthlyAggregate,
          ].includes(Number(data.type))
        ) {
          blob = await orders.exportOrders(data);
        } else if (
          Number(data.type) === Number(OrderExportTypes.StickyLabels)
        ) {
          const exportOrders = await orders.fetchExportOrders(data);

          blob = await ReactPDF.pdf(
            <OrderStickyLabelsPDF orders={exportOrders} />
          ).toBlob();
        } else if (Number(data.type) === Number(OrderExportTypes.WeeklyMenu)) {
          const filteredDishes = dishes.dishes
            .filter(
              (d) =>
                !!d.daysOfWeek?.length ||
                !!d.availabilityDates?.some(
                  (avD) =>
                    dayjs(avD).isAfter(dayjs(data.fromDate)) &&
                    dayjs(avD).isBefore(dayjs(data.toDate))
                )
            )
            .sort((a, b) => {
              return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
            });

          let companies = users.companies.map((userCompany) => {
            let isUserCompany = data.company_ids.filter(
              (companyId: Company) => userCompany.id === companyId.id
            );

            if (isUserCompany) {
              return userCompany;
            }
          });

          var companyString = "";

          companies.map((company) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            if (company) companyString += company.name + " ";
          });

          blob = await ReactPDF.pdf(
            <WeeklyMenuPDF
              data={{
                ...data,
                company: companyString,
              }}
              filteredDishes={filteredDishes}
            />
          ).toBlob();
        }

        if (blob) {
          //  Create blob link to download
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;

          if (Number(data.type) === Number(OrderExportTypes.StickyLabels)) {
            link.setAttribute("target", "_blank");
            link.setAttribute(
              "download",
              `etichette_${dayjs(data.fromDate).format("DD-MM-YYYY")}_${dayjs(
                data.toDate
              ).format("DD-MM-YYYY")}.pdf`
            );
          }
          //  Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode?.removeChild(link);
        }
      } catch (e: any) {
        toast.error(t("messages.errors.userForm.generic"));
        console.log(e);
      }
    },
    [dishes.dishes, orders, t, users.companies]
  );

  const handleChangeCompany = (companyId: any) => {
    if (companyId && String(companyId).length > 0) {
      setActiveCompanyId(Number(companyId));
    } else {
      setActiveCompanyId(null);
    }
  };

  const handleSaveFilter = async (filter: EditedFilter) => {
    try {
      if (filter.id) {
        if (!filter.type) filter.type = 0;
        filter.company_ids = filter.company_ids?.map((company: any) => {
          return company.id;
        });
        await orders.updateFilter({
          ...filter,
          fromDate: undefined,
          toDate: undefined,
        });
        toast.success(t("messages.successes.filterForm.genericEdit"));
      } else {
        const filterName = window.prompt(t("messages.propmpt.filterNameTitle"));

        if (filterName && session.user?.id) {
          filter.filterName = filterName;
          filter.userId = session.user?.id;
          if (!filter.type) filter.type = 0;
          filter.company_ids = filter.company_ids?.map((company: any) => {
            return company.id;
          });

          await orders.createFilter({
            ...filter,
            fromDate: undefined,
            toDate: undefined,
          });
          toast.success(t("messages.successes.filterForm.genericCreate"));
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(t("messages.errors.filterForm.generic"));
    }
  };

  const handleDeleteFilter = async (filter: EditedFilter) => {
    try {
      const confirmResponse = window.confirm(
        t("messages.propmpt.deleteFilterMessage")
      );
      if (confirmResponse) {
        orders.deleteFilter(filter);
        toast.success(t("messages.successes.filterForm.genericDelete"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("messages.errors.filterForm.generic"));
    }
  };

  return (
    <>
      <Content borderBottomWidth="1px">
        <PageHeader
          prev={[]}
          title={t("components.menu.orders")}
          wcText={`${period[0].format("DD/MM")} - ${period[1].format(
            "DD/MM/YYYY"
          )}`}
          goBack={handleGoBack}
          goNext={handleGoNext}
          goToday={handleGoToday}
          onExportClick={open}
        />
      </Content>
      <Content mt={[null, null, null, "0px"]}>
        <Row>
          <MainContent
            periodOrders={periodOrders}
            companies={users.companies}
            onCompanyChange={handleChangeCompany}
            companyId={activeCompanyId}
            workshift={activeWorkshift}
            onWorkshiftChange={setActiveWorkshift}
          />
        </Row>
        <ExportModal
          isOpen={isOpen}
          onClose={close}
          companies={users.companies}
          onSubmit={handleExportOrders}
          onSaveFilter={handleSaveFilter}
          onDeleteFilter={handleDeleteFilter}
          period={period}
          filters={orders.filters}
        />
      </Content>
    </>
  );
});
