import { useTranslation } from "react-i18next";
import { Dish } from "../../../types";
import ScrollBar from "../../Common/Scrollbar";
import AllDishes from "../../UI/Dishes/AllDishes";
import SidebarHeader from "../../UI/Dishes/SidebarHeader";
import { StyledSidebar, StyledContactList, StyledBody } from "./style";

type Props = {
  dishes: Dish[];
  activeId?: number;
  clickHandler: (dish: Dish) => void;
  disableSections?: boolean;
  onAddClick: () => void;
  searchedText: string;
  onSearchChange: (value: string) => any;
};

const Sidebar: React.FC<Props> = ({
  dishes,
  activeId,
  clickHandler,
  disableSections,
  onAddClick,
  searchedText,
  onSearchChange,
}: Props) => {
  const { i18n } = useTranslation();

  return (
    <StyledSidebar $show={true}>
      <SidebarHeader
        searchedText={searchedText}
        setSearchedText={onSearchChange}
        onAddClick={onAddClick}
      />
      <StyledContactList>
        <ScrollBar>
          <StyledBody>
            <AllDishes
              dishes={dishes}
              clickHandler={clickHandler}
              locale={i18n.language}
              activeId={activeId}
            />
          </StyledBody>
        </ScrollBar>
      </StyledContactList>
    </StyledSidebar>
  );
};

export default Sidebar;
