import {
  Allergen,
  Dish,
  DishCategory,
  EditedDish,
  Plan,
  Stores,
} from "../types";
import { makeAutoObservable } from "mobx";
import api from "../services/api";

export class DishesStore {
  stores: Stores;

  isFetching = false;
  isFetchingIngredients = false;
  isFetchingAllergens = false;
  isFetchingDishCategories = false;
  isFetchingPlans = false;

  isSubmitting = false;

  allergens: Allergen[] = [];
  dishCategories: DishCategory[] = [];
  dishTypologies: string[] = [];
  dishes: Dish[] = [];
  plans: Plan[] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  fetchPlans = async () => {
    this.isFetchingPlans = true;

    try {
      this.plans = await api.fetchPlans();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetchingPlans = false;
    }
  };

  fetchAllergens = async () => {
    this.isFetchingAllergens = true;

    try {
      this.allergens = await api.fetchAllergens();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetchingAllergens = false;
    }
  };

  fetchDishCategories = async () => {
    this.isFetchingDishCategories = true;

    try {
      this.dishCategories = await api.fetchDishCategories();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetchingDishCategories = false;
    }
  };

  fetchDishes = async () => {
    this.isFetching = true;

    try {
      this.dishes = await api.fetchDishes();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  fetchDishTypologies = async () => {
    this.isFetching = true;

    try {
      this.dishTypologies = await api.fetchDishTypologies();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  addDish = async (data: EditedDish) => {
    this.isSubmitting = true;
    let newDish;
    try {
      newDish = await api.addDish(data);
      this.dishes.push(newDish);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
    return newDish;
  };

  updateDish = async (id: Dish["id"], data: EditedDish) => {
    this.isSubmitting = true;

    try {
      const updatedDish = await api.updateDish(id, data);

      this.dishes = this.dishes.map((dish) =>
        dish.id === id ? updatedDish : dish
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  deleteDish = async (id: Dish["id"]) => {
    this.isSubmitting = true;
    try {
      await api.deleteDish(id);

      this.dishes = this.dishes.filter((dish) => dish.id !== id);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };
}
