import styled, { themeGet } from "../../../styled";

export const StyledHeader = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 0.8rem;
    margin-bottom: 0;
    color: ${themeGet("colors.gray700")};
  }
`;
