import { SessionStore } from "./stores/Session";
import { FC, SVGAttributes } from "react";
import { DishesStore } from "./stores/DishesStore";
import { OrdersStore } from "./stores/OrdersStore";
import { UsersStore } from "./stores/UsersStore";
import { NewsStore } from "./stores/NewsStore";
import dayjs from "dayjs";
import { Ability } from "@casl/ability";
import { PushNotificationsStore } from "./stores/PushNotificationsStore";

/** DOAR */

export interface IconProps extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string | number;
}

export interface ISubmenu {
  id: number;
  label: string;
  url: string;
  Icon?: FC<IconProps> | undefined;
}

export interface IMegamenu {
  id: number;
  title: string;
  submenu: ISubmenu[];
}

export interface IMenu {
  id: number;
  label: string;
  url: string;
  Icon?: FC<IconProps> | undefined;
  submenu?: ISubmenu[];
  megamenu?: IMegamenu[];
}

/** ./ DOAR */

export type AccessToken = {
  accessToken: string;
};

export enum StorageKey {
  SK_ACCESS_TOKEN = "sk-accessToken",
}

export type Stores = {
  session: SessionStore;
  dishes: DishesStore;
  orders: OrdersStore;
  users: UsersStore;
  news: NewsStore;
  pushNotifications: PushNotificationsStore;
};

export enum RoleName {
  "Administrator" = "Administrator",
  "Kitchen" = "Kitchen",
  "Authenticated" = "Authenticated",
  "OrganizationAdmin" = "OrganizationAdmin",
}

export enum OrderExportTypes {
  DishesCompaniesAggregate, // aggregato per azienda e piatti
  DishesAggregate, // aggregato per piatti
  UserDetail, // dettaglio per singolo utente
  UsersAggregate, // aggregato per utenti
  UsersMonthlyAggregate, // aggregato per utenti (mensile)
  StickyLabels, // etichette per la stampa
  WeeklyMenu, // menu settimanale
}

export type RawAbility = {
  subject: string | string[];
  action: string[];
};

export type Role = {
  id: number;
  name: RoleName;
  acls: Ability;
};

export type Company = {
  id: number;
  name: string;
  workingDays: number[];
};

export type Department = {
  id: number;
  department: string;
};

export type User = {
  id: number;
  email: string;
  username: string;
  referenceOffice: string | null;
  role?: Role;
  company?: Company;
  department?: Department;
  service: boolean;
  workshift: string | null;
};

export type EditedUSer = Pick<
  User,
  "username" | "email" | "referenceOffice" | "service" | "workshift"
> & {
  id?: number;
  password: string;
  companyId: number;
  roleId?: number;
};

export type AlertMessage = {
  type:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  message: string;
};

export type LocaleString = {
  [locale: string]: string;
};

export type NutritionalValues = {
  label: string;
  value100g: string;
  valuePerServing: string;
};

export type Dish = {
  id: number;
  name: LocaleString;
  subname: LocaleString;
  description: LocaleString;
  visible: boolean;
  imgUrls: string[];
  nutritionalTable: NutritionalValues[];
  //ingredients: Ingredient[];
  allergens: Allergen[];
  plans: Plan[];
  plateCategory: DishCategory | null;
  plateIngredients: LocaleString[];
  recommendedPlates: number[];
  platesNumber?: number;
  daysOfWeek?: number[];
  availabilityDates?: Date[];
  typology: string | null;
  partitionType: string | null;
};

export type EditedDish = Pick<
  Dish,
  | "name"
  | "description"
  | "subname"
  | "imgUrls"
  | "nutritionalTable"
  | "visible"
  | "plateIngredients"
  | "recommendedPlates"
  | "daysOfWeek"
  | "availabilityDates"
  | "typology"
  | "partitionType"
> & {
  id?: number;
  categoryId: number | null;
  planIds: number[];
  //ingredientIds: number[];
  allergenIds: number[];
};

// export type Ingredient = {
//   id: number;
//   name: LocaleString;
//   imgUrl: string;
// };

export type Allergen = {
  id: number;
  name: LocaleString;
  imgUrl: string;
};

export type DishCategory = {
  id: number;
  name: LocaleString;
  weight: number;
};

export type DishItemType = Dish;

export type DishSection = {
  label: string;
  items: DishItemType[];
};

export type UserItemType = User;

export type UserSection = {
  label: string;
  items: UserItemType[];
};

export type Plan = {
  id: number;
  name: string;
};

export type News = {
  id: number;
  title: LocaleString;
  content: LocaleString;
  createdAt: Date;
};

export type EditedNews = Pick<News, "title" | "content"> & {
  id?: number;
};

export type Order = {
  id: number;
  user: User;
  date: dayjs.Dayjs;
  dishes: Dish[];
  complessiveFeedback?: number;
  quantityFeedback?: number;
  varietyFeedback?: number;
  workshift: string | null;
};

export type DishesCompaniesAggregateItem = {
  dish: Dish;
  company?: Company;
  count: number;
  date: dayjs.Dayjs;
};

export type DishesAggregateItem = {
  dish: Dish;
  count: number;
  date: dayjs.Dayjs;
};

export type UsersAggregateItem = {
  user: User;
  company?: Company;
  count: number;
  date: dayjs.Dayjs;
};

export type DailyOrders = {
  date: dayjs.Dayjs;
  orders: Order[];
  aggrDishes: DishesAggregateItem[];
};

export type DailySatisfaction = {
  date: dayjs.Dayjs;
  orders: Order[];
  complessiveFeedbackAvg?: number;
  complessiveFeedbackVotes?: number;
  quantityFeedbackAvg?: number;
  quantityFeedbackVotes?: number;
  varietyFeedbackAvg?: number;
  varietyFeedbackVotes?: number;
};

export type ExportOrders = {
  company_ids: [];
  fromDate: Date;
  toDate: Date;
  type: OrderExportTypes;
  workShift: string | null;
  partition: string;
  filter: string;
};

// https://clevertap.com/blog/what-are-push-notification-character-limits/

export type PNMessage = {
  id: number;
  title: string;
  description: string;
  createdAt: dayjs.Dayjs;
  company?: Company;
};

export type EditedPNMessage = Pick<PNMessage, "title" | "description"> & {
  id?: number;
};

export type Filter = {
  id: number;
  userId?: number;
  company_ids?: number[];
  fromDate?: Date;
  toDate?: Date;
  type?: OrderExportTypes;
  workShift?: string | null;
  partition?: string;
  filterName: string;
};

export type EditedFilter = Pick<
  Filter,
  "company_ids" | "fromDate" | "toDate" | "type" | "workShift" | "partition"
> & {
  id?: number;
  userId?: number;
  filterName?: string;
};
