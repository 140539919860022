import {
  DailySatisfaction,
  DishesAggregateItem,
  DishesCompaniesAggregateItem,
  Order,
  UsersAggregateItem,
} from "../types";

export const aggregateDishes = (orders: Order[]): DishesAggregateItem[] => {
  const aggrDishes: DishesAggregateItem[] = [];

  for (let order of orders) {
    for (let dish of order.dishes) {
      const index = aggrDishes.findIndex((item) => item.dish.id === dish.id);
      if (index >= 0) {
        aggrDishes[index].count += dish.platesNumber || 1;
      } else {
        aggrDishes.push({
          dish,
          count: dish.platesNumber || 1,
          date: order.date,
        });
      }
    }
  }

  return aggrDishes;
};

export const calculateAvgFeedback = (
  orders: Order[]
): Partial<DailySatisfaction> => {
  let [
    complessiveFeedbackAvg,
    quantityFeedbackAvg,
    varietyFeedbackAvg,
    complessiveFeedbackVotes,
    quantityFeedbackVotes,
    varietyFeedbackVotes,
  ] = [0, 0, 0, 0, 0, 0];

  for (let order of orders) {
    if (order.complessiveFeedback !== undefined) {
      complessiveFeedbackAvg += order.complessiveFeedback;
      complessiveFeedbackVotes += 1;
    }
    if (order.quantityFeedback !== undefined) {
      quantityFeedbackAvg += order.quantityFeedback;
      quantityFeedbackVotes += 1;
    }
    if (order.varietyFeedback !== undefined) {
      varietyFeedbackAvg += order.varietyFeedback;
      varietyFeedbackVotes += 1;
    }
  }

  return {
    complessiveFeedbackAvg:
      complessiveFeedbackVotes > 0
        ? parseFloat(
            (complessiveFeedbackAvg / complessiveFeedbackVotes).toFixed(2)
          )
        : undefined,
    complessiveFeedbackVotes,
    quantityFeedbackAvg:
      quantityFeedbackVotes > 0
        ? parseFloat((quantityFeedbackAvg / quantityFeedbackVotes).toFixed(2))
        : undefined,
    quantityFeedbackVotes,
    varietyFeedbackAvg:
      varietyFeedbackVotes > 0
        ? parseFloat((varietyFeedbackAvg / varietyFeedbackVotes).toFixed(2))
        : undefined,
    varietyFeedbackVotes,
  };
};

export const calculateTotalAvgFeedback = (
  complessiveFeedback?: number,
  quantityFeedback?: number,
  varietyFeedbackAvg?: number
): number | string => {
  const totalVotes =
    (complessiveFeedback ? 1 : 0) +
      (quantityFeedback ? 1 : 0) +
      (varietyFeedbackAvg ? 1 : 0) || undefined;
  let totalValue = 0;
  if (complessiveFeedback !== undefined) {
    totalValue += complessiveFeedback;
  }
  if (quantityFeedback !== undefined) {
    totalValue += quantityFeedback;
  }
  if (varietyFeedbackAvg !== undefined) {
    totalValue += varietyFeedbackAvg;
  }
  return totalVotes ? parseFloat((totalValue / totalVotes).toFixed(2)) : "-";
};

export const aggregateCompanyDishes = (
  orders: Order[]
): DishesCompaniesAggregateItem[] => {
  const aggrDishes: DishesCompaniesAggregateItem[] = [];

  for (let order of orders) {
    for (let dish of order.dishes) {
      const index = aggrDishes.findIndex(
        (item) =>
          item.dish.id === dish.id &&
          item.company?.id === order.user.company?.id
      );

      if (index >= 0) {
        aggrDishes[index].count += dish.platesNumber || 1;
      } else {
        aggrDishes.push({
          dish,
          count: dish.platesNumber || 1,
          date: order.date,
          company: order.user.company,
        });
      }
    }
  }

  return aggrDishes;
};

export const aggregateUsers = (orders: Order[]): UsersAggregateItem[] => {
  const aggrUsers: UsersAggregateItem[] = [];

  for (let order of orders) {
    if (order.dishes.length > 0) {
      aggrUsers.push({
        user: order.user,
        company: order.user.company,
        date: order.date,
        count: 1,
      });
    }
  }

  return aggrUsers;
};

export const aggregateMonthlyUsers = (
  orders: Order[]
): UsersAggregateItem[] => {
  const aggrUsers: UsersAggregateItem[] = [];

  for (let order of orders) {
    const index = aggrUsers.findIndex((item) => item.user.id === order.user.id);
    if (index >= 0) {
      aggrUsers[index].count += 1;
    } else {
      aggrUsers.push({
        user: order.user,
        company: order.user.company,
        count: 1,
        date: order.date,
      });
    }
  }

  return aggrUsers;
};

export const getWeek = (date: Date) => {
  const currentDate = new Date();
  date = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor(
    (currentDate.getTime() - date.getTime()) / (24 * 60 * 60 * 1000)
  );

  return Math.ceil(days / 7);
};
