import {
  BookOpen,
  Cast,
  Home,
  MessageSquare,
  Smile,
  StopCircle,
  Users,
} from "react-feather";

export const menus = [
  {
    id: 0,
    key: "home",
    label: "components.menu.home",
    url: "/app",
    Icon: Home,
    theme: {
      background: "#002E6B",
      foreground: "#fff",
    },
  },
  {
    id: 1,
    key: "dishes",
    label: "components.menu.dishes",
    url: "/app/dishes",
    Icon: StopCircle,
    theme: {
      background: "#CF5634",
      foreground: "#fff",
    },
  },
  {
    id: 2,
    key: "orders",
    label: "components.menu.orders",
    url: "/app/orders",
    Icon: BookOpen,
    theme: {
      background: "#CF5634",
      foreground: "#fff",
    },
  },
  {
    id: 3,
    key: "users",
    label: "components.menu.users",
    url: "/app/users",
    Icon: Users,
    theme: {
      background: "#CF5634",
      foreground: "#fff",
    },
  },
  {
    id: 4,
    key: "satisfaction",
    label: "components.menu.satisfaction",
    url: "/app/satisfaction",
    Icon: Smile,
    theme: {
      background: "#CF5634",
      foreground: "#fff",
    },
  },
  {
    id: 5,
    key: "news",
    label: "components.menu.news",
    url: "/app/news",
    Icon: Cast,
    theme: {
      background: "#CF5634",
      foreground: "#fff",
    },
  },
  {
    id: 6,
    key: "push-notifications",
    label: "components.menu.pushNotifications",
    url: "/app/push-notifications",
    Icon: MessageSquare,
    theme: {
      background: "#CF5634",
      foreground: "#fff",
    },
  },
];
