import { FC } from "react";
import { ArrowLeft } from "react-feather";
import ScrollBar from "../../Common/Scrollbar";
import { StyledMain, StyledHeader, StyledBody, StyledToggleBtn } from "./style";
import MailBody from "../MailBody";
import { Spinner } from "../../Common/Spinner";
import { PNMessage } from "../../../types";

type Props = {
  message: PNMessage | null;
  isLoading?: boolean;
};

const Main: FC<Props> = ({ message, isLoading = false }) => {
  const bodyHandler = () => {
    // dispatch(toggleBody());
  };

  return (
    <StyledMain>
      <StyledHeader>
        <StyledToggleBtn type="button" onClick={bodyHandler}>
          <ArrowLeft />
        </StyledToggleBtn>
        {isLoading ? <Spinner /> : null}
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          {!isLoading && message && <MailBody message={message} />}
        </StyledBody>
      </ScrollBar>
    </StyledMain>
  );
};

export default Main;
