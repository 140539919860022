import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Navigate, useNavigate } from "react-router";
import { useStores } from "../../hooks/useStores";

export const RedirectLoggedUser: React.FC = observer(() => {
  const { session } = useStores();

  const navigate = useNavigate();

  useEffect(() => {
    if (session.isAuthenticated && session.user) {
      navigate("/app");
      // switch (session.user.role.id) {
      //   case "1":
      //     navigate("/admin/practices");
      //     break;
      //   case "2":
      //     navigate("/user/practices");
      //     break;
      //   case "3":
      //     navigate("/user/practices");
      //     break;
      //   case "4":
      //     navigate("/user/practices");
      //     break;
      //   case "5":
      //     navigate("/user/practices");
      //     break;
      //   default:
      //     break;
      // }
    }
  }, [session, navigate]);

  if (session.isAuthenticated && session.user) {
    switch (session.user.role?.id) {
      case 0:
        navigate("/app");
        break;

      default:
        break;
    }
  }

  return <Navigate to="/login" />;
});
